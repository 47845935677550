import { delay, call, put, /*all,*/ select, takeLatest } from 'redux-saga/effects'
import { push, replace } from 'connected-react-router'

import config from '../../config/'
import * as constants from '../../constants'
import * as viewConstants from './constants'
import { getAccessUser } from './selectors'
import * as viewApi from './api'

function* login( action ) {
  try {
    yield call( [ viewApi, 'login' ], action.payload, viewConstants.LOGIN )
  } catch (e) {
    console.error( e )
  }
}

function* loginSuccess( action ) {
  yield put({type: constants.MODEL_DATA_UPDATE,payload:{user:action.payload}})
}

function* logout( action ) {
  try {
    yield put({type: constants.MODEL_DATA_CLEAR})
    yield call( [ viewApi, 'logout' ], viewConstants.LOGOUT )
  } catch (e) {
    console.error( e )
  }
}

function* check( action ) {
  const accessUser = yield select(getAccessUser)
  if ( accessUser )
    return;
  try {
    yield call( [ viewApi, 'validate' ], viewConstants.CHECK )
    //yield put( replace('/') )
  } catch (e) {
    console.error( e )
  }
}

function* validate( action ) {
  // Only if logged in
  const accessUser = yield select(getAccessUser)
  if ( !accessUser )
    return;
  try {
    yield call( [ viewApi, 'validate' ], viewConstants.VALIDATE )
  } catch (e) {
    console.error( e )
  }
}

function* register( action ) {
  try {
    yield call( [ viewApi, 'register' ], action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* registerSuccess( action ) {
  yield put( replace( "/confirm" ) )
}

function* confirm( action ) {
  try {
    yield call( [ viewApi, 'confirm' ], action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* confirmSuccess( action ) {
  yield put( replace( "/login" ) )
}

export default function*() {
  yield takeLatest( viewConstants.LOGIN, login );
  yield takeLatest( viewConstants.LOGIN+constants.SUCCESS_SUFFIX, loginSuccess );
  yield takeLatest( viewConstants.CHECK+constants.SUCCESS_SUFFIX, loginSuccess );
  yield takeLatest( viewConstants.LOGOUT, logout );
  yield takeLatest( viewConstants.CHECK, check );
  yield takeLatest( viewConstants.REGISTER, register );
  yield takeLatest( viewConstants.REGISTER+constants.SUCCESS_SUFFIX, registerSuccess );
  yield takeLatest( viewConstants.CONFIRM, confirm );
  yield takeLatest( viewConstants.CONFIRM+constants.SUCCESS_SUFFIX, confirmSuccess );
  while( true ) {
    yield delay( config.UPDATE_INTERVAL )
    yield call( validate )
  }
}
