import produce from 'immer'

import entityReducer from '../../entities/reducer'
import { getDatesFromRange } from '../../utils'
import createState from '../../entities/state'

import { entity as userEntity } from '../../entities/user/constants'
import { entity as accountEntity } from '../../entities/account/constants'
import { entity as schoolEntity } from '../../entities/school/constants'

//const range = '';
//const dates = getDatesFromRange( range );

const initialState = createState({
  user: createState(),
  account: createState(),
  schools: createState(),
})

const userReducer = entityReducer( userEntity, initialState );
const accountReducer = entityReducer( accountEntity, initialState );
const schoolsReducer = entityReducer( schoolEntity, initialState );

const reducer = function( state = initialState, action )
{
  return produce( state, newState => {
    switch( action.type ) {
      default :
    }
    //console.log( "S1", state )
    newState.user = userReducer( newState.user, action )
    newState.account = accountReducer( newState.account, action )
    newState.schools = schoolsReducer( newState.schools, action )
  })
}

export default reducer;
