import * as constants from './constants'
import api from '../../api'

const URL_BASE = 'user'

export const getRecord = ( action=null ) => {
  const request = '/'+URL_BASE;
  if ( action )
    return api.makeRequest( request, action );
  return api.makeBasicRequest( request );
}

export const updateRecord = record => {
  console.log( "Updating user", record );
  return api.makeRequest({
    method: 'put',
    url: '/'+URL_BASE,
    data: record,
    },
    constants.RECORD_UPDATE
  )
}

export const deleteRecord = record => {
  console.log( "Deleting record", record );
  return api.makeRequest({
      method: 'delete',
      url: '/'+URL_BASE,
    },
    constants.RECORD_DELETE,
  );
}

export const sendInvite = details => {
  console.log( "Sending invite", details );
  return api.makeRequest({
      method: 'post',
      url: '/'+URL_BASE+'/invite',
      data: details
    },
    constants.SEND_INVITE
  )
}
