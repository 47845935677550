import { createActions } from 'redux-actions'
import * as constants from './constants';

const actions = createActions({
  [constants.RECORDS_LOAD]: null,
  [constants.RECORDS_PAGE]: ( page, size ) => ({ page, size }),
  [constants.RECORDS_SORT]: field => ({ field }),
  [constants.RECORDS_SEARCH]: ( field, text ) => ({ field, text }),
  [constants.RECORDS_FILTER]: ( field, value ) => ({ field, value }),
  [constants.RECORDS_DATE]: date => ( date ),
  [constants.RECORDS_RESET]: null,
  [constants.RECORDS_GENERATE]: date => ( date ),
  [constants.RECORD_LOAD]: id => ( id ),
  [constants.RECORD_UPDATE]: record => ( record ),
  [constants.RECORD_DELETE]: record  => ( record ),
  [constants.ADD]: period => ( period ),
  [constants.EDIT]: period => ( period ),
  [constants.CANCEL]: null,
  [constants.DELETE]: period => ( period ),
});

export default actions