import produce from 'immer'

import entityReducer from '../../entities/reducer'
import { getDatesFromRange } from '../../utils'
import * as constants from '../../constants'
import * as periodConstants from '../../entities/period/constants'
import createState from '../../entities/state'

import { entity as schoolEntity } from '../../entities/school/constants'

const range = '';
const dates = getDatesFromRange( range );

const initialState = {
  event: createState({
    filter: {
      local: false,
      date: {
        range: range,
        start: dates.start,
        end: dates.end,
      },
      filter: {
        EventDeleted: "0"
      },
      sort : {
        col: "EventId",
        asc: true,
      },
    },
  }),
  period: createState({
    filter: {
      local: false,
      date: {
        range: range,
        start: dates.start,
        end: dates.end,
      },
      filter: {
        PeriodDeleted: "0"
      },
      sort : {
        col: "PeriodId",
        asc: true,
      },
    },
  }),
  school: createState(),
};

const periodReducer = entityReducer( 'PERIOD', initialState );
const eventReducer = entityReducer( 'EVENT', initialState );
const schoolReducer = entityReducer( schoolEntity, initialState );

const reducer = function( state = initialState, action )
{
  return produce( state, newState => {
    switch( action.type ) {
      case periodConstants.RECORDS_GENERATE+constants.SUCCESS_SUFFIX :
      case constants.UPDATE_REFRESH :
        if ( !newState.period.filter.local )
          newState.period.invalid = true
        break
      default :
    }
    //console.log( "S1", state )
    newState.period = periodReducer( newState.period, action )
    //console.log( "SP", state )
    newState.event = eventReducer( newState.event, action )
    //console.log( "SE", state )
    newState.school = schoolReducer( newState.school, action )
  })
}

export default reducer;
