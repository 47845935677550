const entity = 'DEFINITION'

export const STATE_INIT = entity+'_STATE_INIT'
export const RECORD_LOAD = entity+'_RECORD_LOAD'
export const RECORD_UPDATE = entity+'_RECORD_UPDATE'
export const RECORD_DELETE = entity+'_RECORD_DELETE'

export const ADD = 'ADD_'+entity
export const EDIT = 'EDIT_'+entity
export const CANCEL = 'CANCEL_'+entity
export const DELETE = 'DELETE_'+entity
