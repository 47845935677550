import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Form } from 'semantic-ui-react';
import { Formik } from 'formik'
import { object, array, string, number, date } from 'yup';
import moment from 'moment'

import { EditModal } from '../../components/modals/'
import { FormInput, FormTextArea, FormDropDown, FormDateTimePicker, FormStatic } from '../../components/forms/formik/'
import { isAdmin, getDataModel, getRules, getFamily, getCarers, getParents, getChildren, getSchools } from '../../model/selectors'
import { ucFirst, SaveButton, CancelButton, AddButton, DeleteButton, MiscButton } from '../../utils'
import entityActions from './actions'

const endTermHolidaySchema = object().shape({
  division:     string().required().label("Division"),
  maxLength:    number().nullable().positive().label("Maximum Stay"),
  firstPeriod:  string().required().label("Initial Period"),
  anchor:       date().label("Anchor Date"),
  anchorOffset: number().label("Anchor Offset"),
})

const midTermHolidaySchema = object().shape({
  division:     string().required().label("Division"),
  maxLength:    number().positive().label("Maximum Stay"),
  firstPeriod:  string().required().label("Initial Period"),
})

const birthdaySchema = object().shape({
  before:       number().positive().required().label("Nights Before"),
  allocation:   string().oneOf(['both','either']).label("And/Or"),
  after:        number().positive().required().label("Nights After"),
})

const rulesSchema = object().shape({
  conditions: object().shape({
                defaultCarerId: number().required().label("Fallback Carer"),
                handoverTime:   date().required().label("Handover Time"),
              }),
  cycles:     array().of(
                object().shape({
                  carerId:    number().required().label("Carer"),
                  dayOfWeek:  number().min(1).max(7).required().label("Day Of Week"),
                  duration:   number().positive().required().label("Duration"),
                  tag:        string().required().label("Label"),
                })
              ),
  holidays:   object().shape({
                'end-term':   object().shape({
                  1: endTermHolidaySchema,
                  2: endTermHolidaySchema,
                  3: endTermHolidaySchema,
                }),
                'mid-term':   object().shape({
                  'half-term': midTermHolidaySchema,
                }),
              }),
  events:     object().shape({
                birthdays:  object().shape({
                  'parent': birthdaySchema,
                  'child': birthdaySchema,
                }),
              }),
});

const ruleSchema = object().shape({
  rules:  rulesSchema,
})

class RuleFormBase extends Component {
  render() {
    const { values, family, dataModel } = this.props

    console.log( "RND", this.props )
    console.log( "ERR", this.props.errors )
    const ruleTypes = this.props.dataModel.ruleTypes.map( ruleType => ({ value: ruleType, text: ruleType.split( '-' ).map( type => ucFirst(type) ).join( ' / ' ) }) )
    const schools = this.props.schools.map( school => ({ value: school.SchoolId, text: school.SchoolName }) )
    const carers = this.props.carers.map( member => ({ value: parseInt(member.MemberId,10), text: ucFirst(member.MemberFirstName) }) )
    const dows = [...Array(7)].map( (value,index) => ({ value: index+1, text: moment().isoWeekday(index+1).format("dddd") }) )
    const schoolYearPatternIds = this.props.schools.reduce( ( patternIds, school ) => patternIds.add( school.SchoolYearPatternId ), new Set() )
    const schoolYearPatternId = schoolYearPatternIds.size === 1 ? schoolYearPatternIds.values().next().value : null
    const schoolYearPattern = this.props.dataModel.schoolYearPatterns.find( pattern => pattern.SchoolYearPatternId === schoolYearPatternId )
    const holidays = schoolYearPattern ? schoolYearPattern.terms.map( term => ({ value: term.SchoolYearTermNum, text: term.SchoolYearTermHolidayName }) ) : []
    const terms = schoolYearPattern ? schoolYearPattern.terms.map( term => ({ value: term.SchoolYearTermNum, text: term.SchoolYearTermName }) ) : []
    const breaks = {
      'half-term': "Half Term",
    }
    const divisions = [
      { value: "half", text: "Half" },
    ]
    const firstPeriods = [
      { value: "alternatePrevious", text: "Alternate from previous holiday" },
      { value: "alternateCarer", text: "Alternate from previous carer" },
      { value: "alternateWeekend", text: "Alternate from previous weekend" },
    ]
    const allocations = [
      { value: "both", text: "AND" },
      { value: "either", text: "OR" },
    ]
    return (
      <Form
        success={this.props.valid}
        warning={this.props.warning}
        error={this.props.invalid}
        onSubmit={this.props.handleSubmit}
      >
        <Header block attached="top" content="Conditions"/>
        <Segment attached>
          <Form.Group>
            <FormInput
              type="text"
              name="RuleLabel"
              prompt="Label"
              required
              width={6}
              fluid
            />
            <FormInput
              type="text"
              name="RuleTag"
              prompt="Tag"
              readOnly
              width={6}
              fluid
            />
          </Form.Group>
          <Form.Group style={{display:'none'}}>
            <FormDropDown
              name={`rules.conditions.defaultCarerId`}
              prompt="Fallback Carer"
              selection
              options={carers}
              required
              width={2}
              fluid
            />
            <FormDateTimePicker
              name={`rules.conditions.handoverTime`}
              prompt="Handover Time"
              displayFormat="HH:mm"
              storeFormat="HH:mm"
              required
              date={false}
              time={true}
              width={2}
            />
          </Form.Group>
          <Form.Group>
            <FormDropDown
              name={`RuleType`}
              prompt="Rule Type"
              selection
              options={ruleTypes}
              required
              width={4}
              fluid
            />
            {values.RuleType.match(/^school/) && 
            <Fragment>
            <FormDropDown
              name={`schools`}
              prompt="Schools"
              selection
              multiple
              options={schools}
              required
              width={6}
              fluid
            />
            {values.RuleType.match(/-holiday/) && 
            <FormDropDown
              name={`holidays`}
              prompt="Holidays"
              selection
              multiple
              options={holidays}
              required
              width={5}
              fluid
            />
            }
            {values.RuleType.match(/-term/) && 
            <FormDropDown
              name={`terms`}
              prompt="Terms"
              selection
              multiple
              options={terms}
              required
              width={4}
              fluid
            />
            }
            </Fragment>
            }
          </Form.Group>
          <Form.Group>
            <FormInput
              type="text"
              name="selector"
              prompt="SelectorLabel"
              required
              width={8}
              fluid
              readOnly
            />
          </Form.Group>
        </Segment>
        {values.allocator.cycles &&
        <Fragment>
          <Header block attached>
            General Cycles
            <AddButton content="Add Cycle" onClick={()=>this.addCycle()}/>
          </Header>
          <Segment attached>
            {values.allocator.cycles.map( (cycle,index) =>
            <Form.Group key={index}>
              <FormDropDown
                name={`allocator.cycles.${index}.carerId`}
                prompt={index===0?"Carer":undefined}
                selection
                options={carers}
                width={4}
                fluid
              />
              <FormDropDown
                name={`allocator.cycles.${index}.dayOfWeek`}
                prompt={index===0?"Start Day":undefined}
                selection
                options={dows}
                width={3}
                fluid
              />
              <FormInput
                type="number"
                name={`allocator.cycles.${index}.duration`}
                prompt={index===0?"Duration":undefined}
                required
                width={2}
                fluid
              />
              <FormInput
                type="text"
                name={`allocator.cycles.${index}.tag`}
                prompt={index===0?"Label":undefined}
                required
                width={7}
                fluid
              />
            </Form.Group>
            )}
          </Segment>
        </Fragment>
        }
        {values.type==='holibobs' &&
        <Fragment>
        <Header block attached content="School Holidays"/>
        <Segment attached>
          <Header sub dividing content="Main Holidays"/>
          {Object.keys(values.rules.holidays['end-term']).map( index =>
          <Form.Group key={index}>
            <FormStatic
              prompt={index==="1"?"Holiday":undefined}
              value={terms[index]}
              width={2}
            />
            <FormDropDown
              name={`rules.holidays['end-term'].${index}.division`}
              prompt={index==="1"?"Division":undefined}
              selection
              options={divisions}
              required
              width={2}
              fluid
            />
            <FormInput
              type="number"
              name={`rules.holidays['end-term'].${index}.maxLength`}
              prompt={index==="1"?"Maximum Stay":undefined}
              width={1}
              fluid
            />
            <FormDropDown
              name={`rules.holidays['end-term'].${index}.firstPeriod`}
              prompt={index==="1"?"Initial Period":undefined}
              selection
              options={firstPeriods}
              required
              width={3}
              fluid
            />
            <FormDateTimePicker
              name={`rules.holidays['end-term'].${index}.anchor`}
              prompt={index==="1"?"Anchor Date":undefined}
              displayFormat="Do MMMM"
              storeFormat="DD-MM"
              required
              date={true}
              width={2}
            />
            <FormInput
              type="number"
              name={`rules.holidays['end-term'].${index}.anchorOffset`}
              prompt={index==="1"?"Offset from anchor date":undefined}
              width={2}
              fluid
            />
          </Form.Group>
          )}
          <Header sub dividing content="Term Breaks"/>
          {Object.keys(values.rules.holidays['mid-term']).map( index =>
          <Form.Group key={index}>
            <FormStatic
              prompt={index==="1"?"Break":undefined}
              value={breaks[index]}
              width={2}
            />
            <FormDropDown
              name={`rules.holidays['mid-term'].${index}.division`}
              prompt={index==="1"?"Division":undefined}
              selection
              options={divisions}
              required
              width={2}
              fluid
            />
            <FormInput
              type="number"
              name={`rules.holidays['mid-term'].${index}.maxLength`}
              prompt={index==="1"?"Maximum Stay":undefined}
              width={1}
              fluid
            />
            <FormDropDown
              name={`rules.holidays['mid-term'].${index}.firstPeriod`}
              prompt={index==="1"?"Initial Period":undefined}
              selection
              options={firstPeriods}
              required
              width={3}
              fluid
            />
          </Form.Group>
          )}
        </Segment>
        </Fragment>
        }
        {values.type==='event-borthday' &&
        <Fragment>
        <Header block attached content="Birthdays"/>
        <Segment attached="bottom">
          {Object.keys(values.rules.events.birthdays).map( index =>
          <Form.Group key={index}>
            <FormStatic
              prompt={index==="parent"?"":undefined}
              value={ucFirst(index)}
              width={2}
            />
            <FormInput
              type="number"
              name={`rules.events.birthdays.${index}.before`}
              prompt={index==="parent"?"Nights Before":undefined}
              required
              width={2}
              fluid
            />
            <FormDropDown
              name={`rules.events.birthdays.${index}.allocation`}
              prompt={index==="parent"?"And/Or":undefined}
              selection
              options={allocations}
              required
              width={2}
              fluid
            />
            <FormInput
              type="number"
              name={`rules.events.birthdays.${index}.after`}
              prompt={index==="parent"?"Nights After":undefined}
              required
              width={2}
              fluid
            />
          </Form.Group>
          )}
        </Segment>
        </Fragment>
        }
      </Form>
    )
  }
}

const RuleForm = connect(store=>({
  dataModel: getDataModel(store),
  family: getFamily( store ),
  carers: getCarers( store ),
  schools: getSchools( store ),
  //parents: getParents( store ),
  //children: getChildren( store ),
}),
  null
)(RuleFormBase)

export default class EditRule extends Component {
  render() {
    console.log( "EEM", this.props )
    const { record } = this.props
    if ( !record )
      return null;
    const dur = moment.duration(record.rules.conditions.handoverTime,"HH:MM")
    console.log( dur );
    record.rules.conditions.handoverTime = moment().startOf('day').add(dur)
    Object.keys(record.rules.holidays['end-term']).forEach( index => {
      if ( record.rules.holidays['end-term'][index].anchor )
        record.rules.holidays['end-term'][index].anchor = moment(record.rules.holidays['end-term'][index].anchor,'MM-DD').startOf('day')
    });
    //const editing = record && record.FamilyId;
    //const title = (editing?"Edit":"Create")+" Family"+(editing?(" "+record.FamilyId):"");
    const schoolMatch = record.selector.match( /^(school-(\d+))/ )
    console.log( "sm", schoolMatch )
    console.log( "R", record )
    return(
      <Formik
        initialValues={record}
        isInitialValid={record.FamilyId!==undefined}
        validationSchema={ruleSchema}
        onSubmit={this.props.onChange}
        render={ props =>
          <RuleForm {...props}/>
        }
      />
    )
  }
}

class EditRuleModalBase extends Component {
  render() {
    console.log( "EEM", this.props )
    const { record, show, toggle } = this.props
    if ( !record )
      return null;
    const editing = record && record.RuleId;
    const title = (editing?"Edit":"Create")+" Rule"+(editing?(" "+record.RuleId):"");
    record.schools = [];
    const schoolMatch = record.selector.match( /(school-(\d+)?)/ )
    console.log( "sm", record.selector, schoolMatch )
    if ( schoolMatch && schoolMatch[2] )
      record.schools.push( schoolMatch[2] )
    record.terms = [];
    record.holidays = [];
    if ( schoolMatch )
    {
      const termMatch = record.selector.match( /(term-(\d+)?)/ )
      if ( termMatch && termMatch[2] )
        record.terms.push( termMatch[2] )
      const holidayMatch = record.selector.match( /(holiday-(\d+)?)/ )
      if ( holidayMatch && holidayMatch[2] )
        record.holidays.push( holidayMatch[2] )
    }
    return(
      <Formik
        initialValues={record}
        isInitialValid={record.RuleId!==undefined}
        validationSchema={ruleSchema}
        onSubmit={this.props.onChange}
        render={ props => {
          return(
            <EditModal
              show={show}
              toggle={toggle}
              title={title}
              canSubmit={()=>props.isValid}
              onSubmit={props.handleSubmit}
              onDelete={this.props.canEdit&&this.props.onDelete}
            > 
              <RuleForm {...props}/>
            </EditModal>
          )
        }}
      />
    )
  }
}

export const EditRuleModal = connect( store => ({
  isAdmin: isAdmin( store ),
}),
{
  ...entityActions
})(EditRuleModalBase)
 
