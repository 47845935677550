import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'semantic-ui-react'

export const FormButton = props => {
  const { as, fluid, width, ...buttonProps } = props;
  return(
    <Form.Field fluid={fluid} width={width}>
      <label>{'\u2003'}</label>
      {React.createElement( as||Button, buttonProps )}
    </Form.Field>
  )
}

export default FormButton
