import React from 'react'
import PropTypes from 'prop-types'
import { Form, Dropdown, Message } from 'semantic-ui-react'

export const FormFieldDropDown = props => {
  //console.log( "FFD", props )
  const { prompt, name, value, width, inline, required, nomessage, valid, error, ...inputProps } = props
  return(
    <Form.Field
      width={width}
      error={!valid}
      inline={inline}
      required={required}
    >
      {prompt!==undefined &&
      <label>{prompt?prompt:'\u2003'}</label>
      }
      <Dropdown
        name={name}
        value={value}
        {...inputProps}
      />
      {(!nomessage && !valid) &&
      <Message error>{error}</Message>
      }
    </Form.Field>
  )
}

FormFieldDropDown.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  error: PropTypes.string,
}

FormFieldDropDown.defaultProps = {
  valid: true,
}

export default FormFieldDropDown
