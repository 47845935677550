import React from 'react'
import PropTypes from 'prop-types'
import { Form, Message } from 'semantic-ui-react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import merge from 'deepmerge'

const FormFieldDayPicker = props => {
  //console.log( "FFDP", props );
  const { prompt, name, value, format, width, inline, required, nomessage, valid, error, dayPickerProps, ...inputProps } = props;
  const datePlaceholder = format && format.toLowerCase()
  const defaultPickerProps = {
    showOutsideDays: true,
    todayButton: "Today",
  }
  const mergedPickerProps = dayPickerProps ? merge( defaultPickerProps, dayPickerProps ) : defaultPickerProps;
  return(
    <Form.Field width={width} error={!valid} inline={inline} required={required}>
      {prompt!==undefined &&
      <label>{prompt?prompt:'\u2003'}</label>
      }
      <DayPickerInput
        dayPickerProps={mergedPickerProps}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={datePlaceholder}
        value={value}
        format={format}
        {...inputProps}
        inputProps={{size:10}}
        className={{
          container: "ui input",
        }}
      />
      {(!nomessage && !valid) &&
      <Message error={!valid}>{error}</Message>
      }
    </Form.Field>
  );
}

FormFieldDayPicker.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  error: PropTypes.string,
};

FormFieldDayPicker.defaultProps = {
  valid: true,
};

export default FormFieldDayPicker
