export const entity = 'RULE'

export const STATE_INIT = entity+'_STATE_INIT'
export const RECORDS_LOAD = entity+'_RECORDS_LOAD'
export const RECORD_LOAD = entity+'_RECORD_LOAD'
export const RECORD_UPDATE = entity+'_RECORD_UPDATE'
export const RECORD_DELETE = entity+'_RECORD_DELETE'

export const ADD = 'ADD_'+entity
export const EDIT = 'EDIT_'+entity
export const CANCEL = 'CANCEL_'+entity
export const DELETE = 'DELETE_'+entity

export const EDIT_MODAL_OPEN = 'EDIT_'+entity+'_MODAL_OPEN'
export const EDIT_MODAL_CLOSE = 'EDIT_'+entity+'_MODAL_CLOSE'
export const DELETE_MODAL_OPEN = 'DELETE_'+entity+'_MODAL_OPEN'
export const DELETE_MODAL_CLOSE = 'DELETE_'+entity+'_MODAL_CLOSE'
