import React from 'react'
import PropTypes from 'prop-types'
import { Form, Message } from 'semantic-ui-react'

const FormField = props => {
  //console.log( "FF", props )
  const { children, prompt, fieldType, name, value, width, inline, required, nomessage, valid, error, ...inputProps } = props
  return React.createElement( Form.Field, { width, inline, required, error: !valid },
    prompt!==undefined && <label>{prompt?prompt:'\u2003'}</label>,
    React.createElement( fieldType, { name, value, ...inputProps } ),
    (!nomessage && !valid) && <Message error={!valid}>{error}</Message>
  )
}

FormField.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  valid: PropTypes.bool,
  error: PropTypes.string,
}

FormField.defaultProps = {
  valid: true,
}

export default FormField
