import React from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import FormField from '../field'

const FormFieldWrapper = props => {
  //console.log( "FFw", props )
  const { userOnChange, userOnBlur, field: { name, value, onChange, onBlur }, form: { touched: formTouched, errors }, ...inputProps } = props
  const touched = getIn( formTouched, name )
  const pristine = !touched
  const error = getIn( errors, name )
  const inputValid = pristine || error===undefined
  return(
    <FormField
      name={name}
      value={value}
      valid={inputValid}
      error={error}
      {...inputProps}
      onChange={e=>{
        onChange(e)
        userOnChange&&userOnChange(e,{name,value:e.target.value})
      }}
      onBlur={e=>{
        onBlur(e)
        userOnBlur&&userOnBlur(e,{name})
      }}
    />
  )
}

export default FormFieldWrapper
