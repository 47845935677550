import React from 'react'
import PropTypes from 'prop-types'
import { Form, Message, Popup } from 'semantic-ui-react'
import { SketchPicker as ColorPicker } from 'react-color'
//import merge from 'deepmerge'

const FormFieldColorPicker = props => {
  //console.log( "FFCP", props );
  const { prompt, name, value, format, width, title, header, inline, required, nomessage, valid, error, colorPickerProps, ...inputProps } = props;
  //const defaultPickerProps = {
  //}
  //const mergedPickerProps = colorPickerProps ? merge( defaultPickerProps, colorPickerProps ) : defaultPickerProps;
  return(
    <Form.Field width={width} error={!valid} inline={inline} required={required}>
      {prompt!==undefined &&
      <label>{prompt?prompt:'\u2003'}</label>
      }
      <div
        className="ui input"
        style={{
          padding: '7.5px 12px',
          background: '#fff',
          borderRadius: '3px',
          border: '1px solid rgba(34,36,38,.15)',
          display: 'inline-block',
          cursor: 'pointer',
        }}
        title={title}
      >
        <Popup
          trigger={
            <div
              style={{
                height: '21px',
                borderRadius: '2px',
                backgroundColor: value,
              }}>
            </div>
          }
          on='click'
          position='top right'
        >
          <Popup.Header>
            {header}
          </Popup.Header>
          <Popup.Content>
            <ColorPicker
              color={value}
              {...inputProps}
            />
          </Popup.Content>
        </Popup>
      </div>
      {(!nomessage && !valid) &&
      <Message error={!valid}>{error}</Message>
      }
    </Form.Field>
  );
}

FormFieldColorPicker.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  error: PropTypes.string,
  title: PropTypes.string,
  header: PropTypes.string,
};

FormFieldColorPicker.defaultProps = {
  valid: true,
  header: "Select Colour",
};

export default FormFieldColorPicker
