import React from 'react'
import { Field } from 'formik'
import { TextArea } from 'semantic-ui-react'

import FormFieldWrapper from './field'

const FormTextArea = props => {
  const { onChange, onBlur, ...inputProps } = props
  return(
    <Field
      component={FormFieldWrapper}
      fieldType={TextArea}
      userOnChange={onChange}
      userOnBlur={onBlur}
      {...inputProps}
    />
  ) 
}

export default FormTextArea
