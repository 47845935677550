import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Segment, Message, Form } from 'semantic-ui-react';
import { Formik, getIn } from 'formik'
import { object, string, ref } from 'yup';

import { EditModal } from '../../components/modals/'
import { FormInput, FormCheckBox, FormTextArea, FormDropDown, FormDateTimePicker, FormColorPicker } from '../../components/forms/formik/'
import { getDataModel } from '../../model/selectors'
import { SaveButton, CancelButton, AddButton, DeleteButton, MiscButton } from '../../utils'
import userActions from './actions'
import Config from '../../config/'

const inviteSchema = object().shape({
  email:  string().email().required().label("User Email"),
  role:   string().required().label("User Role"),
});

class InviteUserFormBase extends Component {
  render() {
    const { values: record, dataModel } = this.props

    console.log( "RND", this.props )
    const memberRoles = (dataModel.memberRoles || []).map( role => ({ value: role.MemberRole, text: role.MemberRoleName, description: role.MemberRoleDescription }) )

    return (
      <Form success={this.props.valid} warning={this.props.warning} error={this.props.invalid}>
        <Segment attached="top">
          <Header content={"Send "+this.props.member.MemberFirstName+" an invitation to register for this family on "+Config.TITLE}/>
          <Form.Group>
            <FormInput
              name="email"
              prompt="Their Email Address"
              type="email"
              placeholder="Email Address"
              required
              width={6}
              fluid
            />
            <FormDropDown
              name="role"
              prompt="Invite as..."
              required
              selection
              options={memberRoles}
              width={10}
              fluid
            />
          </Form.Group>
        </Segment>
      </Form>
    )
  }
}

const InviteUserForm = connect(store=>({
  dataModel: getDataModel(store),
}),
  null
)(InviteUserFormBase)

class InviteUserModalBase extends Component {
  render() {
    const { member, show, toggle } = this.props
    if ( !member )
      return null;
    return(
      <Formik
        initialValues={{email:"",role:member.MemberRole,member}}
        validationSchema={inviteSchema}
        onSubmit={this.props.sendInviteUser}
        render={ props => {
          return(
            <EditModal
              show={show}
              toggle={toggle}
              title="Invite User"
              submitText="Send Invitation"
              canSubmit={()=>props.isValid}
              onSubmit={props.handleSubmit}
            >
              <InviteUserForm {...props} member={member}/>
            </EditModal>
          )
        }}
      />
    )
  }
}

export const InviteUserModal = connect(
  null,
{
  ...userActions
})(InviteUserModalBase)

const userSchema = object().shape({
  UserEmail:  string()
    .email()
    .required()
    .label("Email Address"),
  password:   string()
    .nullable()
    .min(8)
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain at least one uppercase, one lowercase, one number and one special case character"
    )
    .label("New Password"),
  password2: string()
    .nullable()
    .when('password',{is:value=>!!value,then:string().required()})
    .oneOf([ref('password'), null], "Passwords do not match")
    //.test('passwords-match', 'Passwords must match ya fool', function(value) {
      //return this.parent.password === value;
    //}),
    .label('Confirm New Password'),
})

class Preference extends Component {
  render() {
    const prefs = this.props.prefs
    const prefsPath = this.props.path
    const prefName = this.props.name
    const pref = prefs[prefName]
    const commonAttrs = {
      name: `${prefsPath}.${prefName}`,
      prompt: pref.PrefPrompt,
      title: pref.PrefDesc,
    }
    let prefAttrs = {}
    if ( pref.PrefAttrs )
    {
      try {
        prefAttrs = JSON.parse(pref.PrefAttrs)
      } catch (e) {
        console.error( "Can't parse attrs for pref", pref, e )
        return null;
      }
    }
    const prefOptions = {}
    return(
      <Fragment key={pref.PrefName}>
      {pref.PrefType === 'boolean' &&
      <FormCheckBox
        {...commonAttrs}
        {...prefAttrs}
        slider
        trueValue="1"
        falseValue="0"
      />
      }
      {pref.PrefType === 'number' &&
      <FormInput
        type="number"
        {...commonAttrs}
        {...prefAttrs}
        placeholder={prefs[pref.PrefName].PrefDefault}
      />
      }
      {pref.PrefType === 'string' &&
      <FormInput
        {...commonAttrs}
        {...prefAttrs}
        placeholder={prefs[pref.PrefName].PrefDefault}
      />
      }
      {pref.PrefType === 'text' &&
      <FormTextArea
        {...commonAttrs}
        {...prefAttrs}
        placeholder={prefs[pref.PrefName].PrefDefault}
      />
      }
      {pref.PrefType === 'list' &&
      <FormDropDown
        {...commonAttrs}
        {...prefAttrs}
        placeholder={prefs[pref.PrefName].PrefDefault}
        selection
        options={prefOptions}
        fluid
      />
      }
      {pref.PrefType === 'color' &&
      <FormColorPicker
        {...commonAttrs}
        {...prefAttrs}
      />
      }
      {pref.PrefType === 'datetime' &&
      <FormDateTimePicker
        {...commonAttrs}
        {...prefAttrs}
        displayFormat="Do MMM YYYY"
        storeFormat="YYYY-MM-DD"
      />
      }
      </Fragment>
    )
  }
}

class Preferences extends Component {
  render() {
    const { path, prefs } = this.props;
    const sortedPrefs = Object.values(prefs).sort( ( a, b ) => parseInt(a.PrefSeq,10)-parseInt(b.PrefSeq,10) )
    return(
      <Form.Group>
      {sortedPrefs.map( ( pref, index ) => 
        <Preference key={pref.PrefId} name={pref.PrefName} path={path} prefs={prefs}/>
      )}
      </Form.Group>
    )
  }
}

class UserFormBase extends Component {
  render() {
    const { values, dataModel: { prefs } } = this.props

    console.log( "UserForm RND", this.props, prefs )
    return (
      <Form success={this.props.isValid} warning={this.props.warning} error={!!Object.keys(this.props.errors).length} onSubmit={this.props.handleSubmit}>
        <Header block attached="top" content="Login Details"/>
        <Segment attached>
          <Form.Group>
            <FormInput
              name="UserEmail"
              prompt="Your Email Address"
              type="email"
              icon="user"
              iconPosition="left"
              placeholder="Email Address"
              required
              width={16}
              fluid
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              name="password"
              type="password"
              autoComplete="new-password"
              prompt="New Password"
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              width={16}
              fluid
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              name="password2"
              type="password"
              autoComplete="new-password"
              prompt="Confirm New Password"
              icon="lock"
              iconPosition="left"
              placeholder="Confirm Password"
              required={values.password}
              width={16}
              fluid
            />
          </Form.Group>
        </Segment>
        <Header block attached="top" content="Preferences"/>
        <Segment attached>
          <Preferences path="prefs" prefs={prefs}/>
        </Segment>
        <Segment textAlign="right" attached="bottom">
          <SaveButton type="submit" disabled={!this.props.isValid||!this.props.dirty}/>
          <CancelButton onClick={this.cancel}/>
        </Segment>
      </Form>
    )
  }
}

const UserForm = connect(store=>({
  dataModel:getDataModel(store),
}),
  null
)(UserFormBase)

export default class EditUser extends Component {
  render() {
    console.log( "Edit User - RND", this.props )
    const { record } = this.props
    if ( !record )
      return null;
    console.log( "R", record )
    return(
      <Formik
        initialValues={record}
        validationSchema={userSchema}
        onSubmit={this.props.onChange}
        render={ props => {
          console.log( "Edit User - FMK", props );
          return(
            <UserForm {...props}/>
          )}
        }
      />
    )
  }
}