import { createSelector } from 'reselect'
import { filterRecords } from '../../utils'

export const getViewState = state => state.views.calendar
/*
export const getPeriodState = state => state.views.calendar.period
export const getPeriodRecords = state => state.views.calendar.period.records
export const getPeriodFilter = state => state.views.calendar.period.filter
*/

export const getPeriodState = createSelector(
  [ getViewState ],
  viewState => viewState.period
)

export const getPeriodRecords = createSelector(
  [ getPeriodState ],
  periodState => periodState.records
)

export const getPeriodFilter = createSelector(
  [ getPeriodState ],
  periodState => periodState.filter
)

export const getPeriodRecord = createSelector(
  [ getPeriodState ],
  periodState => periodState.record
)

export const getFilteredPeriodRecords = createSelector(
  [ getPeriodRecords, getPeriodFilter ],
  ( records, filter ) => {
    if ( filter.local )
      return filterRecords( records, filter )
    return { records, filter }
  }
)

export const getEventState = createSelector(
  [ getViewState ],
  viewState => viewState.event
)

export const getEventRecords = createSelector(
  [ getEventState ],
  eventState => eventState.records
)

export const getEventFilter = createSelector(
  [ getEventState ],
  eventState => eventState.filter
)

export const getEventRecord = createSelector(
  [ getEventState ],
  eventState => eventState.record
)

export const getFilteredEventRecords = createSelector(
  [ getEventRecords, getEventFilter ],
  ( records, filter ) => {
    if ( filter.local )
      return filterRecords( records, filter )
    return { records, filter }
  }
)

export const getSchoolState = createSelector(
  [ getViewState ],
  viewState => viewState.school
)

export const getSchoolRecords = createSelector(
  [ getSchoolState ],
  schoolState => schoolState.records
)

export const getInvalid = createSelector(
  [ getPeriodState, getEventState ],
  ( periodState, eventState ) => periodState.invalid || eventState.invalid
)
