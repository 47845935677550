import { delay, call, put, all, select, takeLatest } from 'redux-saga/effects'

import * as constants from '../../constants'
import * as ruleConstants from '../../entities/rule/constants'
import * as ruleApi from '../../entities/rule/api'
//import { getSchoolState, getRuleState } from './selectors'
import { fetchDependentData, fetchFilteredRecords } from '../../model/sagas'

function *fetchBaseData() {
  yield call( fetchDependentData, [ 'memberAttrs', 'account', 'schoolAttrs', 'schools', 'ruleAttrs' ] )
}

/*
function* loadSchoolRecord( action ) {
  try {
    //yield call( fetchBaseData )
    yield call( [ schoolApi, 'getRecord' ], action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function *schoolRecordLoaded( action ) {
  const record = action.payload;
  yield put({type: schoolConstants.EDIT_MODAL_OPEN, payload: record});
}

function* updateSchoolRecord( action ) {
  try {
    yield call( [ schoolApi, 'updateRecord' ],  action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *schoolRecordUpdated( action ) {
  yield put({type: schoolConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadSchoolRecords, 0 );
}

function *deleteSchool( action ) {
  const record = action.payload;
  yield put({type: schoolConstants.DELETE_MODAL_OPEN, payload: record});
}

function* deleteSchoolRecord( action ) {
  try {
    yield call( [ schoolApi, 'deleteRecord' ], action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *schoolRecordDeleted( action ) {
  yield put({type: schoolConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadSchoolRecords, 0 )
}
*/

function *refreshRecords() {
  yield all([
    call( fetchBaseData ),
    //call( loadSchoolRecords, 0 ),
    //call( loadRuleRecords, 0 ),
  ])
}

function* loadRuleRecords( action ) {
  try {
    yield call( fetchBaseData )
    yield call( [ ruleApi, 'getRecords' ], action.type )
  } catch (e) {
    console.error( e )
  }
}

function* updateRuleRecord( action ) {
  try {
    yield call( [ ruleApi, 'updateRecord' ], action.payload )
  } catch (e) {
    console.error( e )
  }
}
/*
function *ruleRecordUpdated( action ) {
  yield put({type: ruleConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadRuleRecords, 0 );
}

function *deleteRule( action ) {
  const record = action.payload;
  yield put({type: ruleConstants.DELETE_MODAL_OPEN, payload: record});
}

function* deleteRuleRecord( action ) {
  try {
    yield call( [ ruleApi, 'deleteRule' ], action.payload.record )
  } catch (e) {
    console.error( e )
  }
}

function *ruleRecordDeleted( action ) {
  yield put({type: ruleConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadRuleRecords, 0 )
}
*/
export default function*() {
  //yield takeLatest( schoolConstants.RECORD_LOAD, loadSchoolRecord );
  //yield takeLatest( schoolConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX, schoolRecordLoaded );
  //yield takeLatest( schoolConstants.RECORD_UPDATE, updateSchoolRecord );
  //yield takeLatest( schoolConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, schoolRecordUpdated );
  //yield takeLatest( schoolConstants.RECORD_DELETE, deleteSchoolRecord );
  //yield takeLatest( schoolConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, schoolRecordDeleted );
  //yield takeLatest( schoolConstants.DELETE, deleteSchool );
  //yield takeLatest( ruleConstants.ADD, addRule );
  //yield takeLatest( ruleConstants.EDIT, editRule );
  yield takeLatest( ruleConstants.RECORDS_LOAD, loadRuleRecords );
  yield takeLatest( ruleConstants.RECORD_UPDATE, updateRuleRecord );
  //yield takeLatest( ruleConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, ruleRecordUpdated );
  //yield takeLatest( ruleConstants.RECORD_DELETE, deleteRuleRecord );
  //yield takeLatest( ruleConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, ruleRecordDeleted );
  //yield takeLatest( ruleConstants.DELETE, deleteRule );
  //yield takeLatest( ruleConstants.CANCEL, cancelRule );
  yield takeLatest( constants.UPDATE_REFRESH, refreshRecords );
}
