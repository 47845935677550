import React from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'

import FormFieldCheckBox from '../checkbox'

const FormFieldCheckBoxWrapper = props => {
  //console.log( "FFCw", props )
  const { userOnChange, userOnBlur, trueValue, falseValue, field: { name, value }, form: { touched: formTouched, errors, setFieldValue, setFieldTouched }, ...inputProps } = props
  const touched = getIn( formTouched, name )
  const pristine = !touched
  const error = getIn( errors, name )
  const inputValid = pristine || error===undefined
  const checked = value === trueValue
  return(
    <FormFieldCheckBox
      name={name}
      valid={inputValid}
      error={error}
      checked={checked}
      {...inputProps}
      onChange={(e,data)=>{
        setFieldValue(name,data.checked?trueValue:falseValue)
        setFieldTouched(name)
        userOnChange&&userOnChange(e,data)
      }}
      onBlur={(e,data)=>{
        setFieldTouched(name)
        userOnBlur&&userOnBlur(e,data)
      }}
    />
  )
}

const FormCheckBox = props => {
  //console.log( "FC", props )
  let { trueValue, falseValue, onChange, onBlur, ...checkProps } = props
  if ( trueValue === undefined )
    trueValue = true
  if ( falseValue === undefined )
    falseValue = false
  return(
    <Field
      component={FormFieldCheckBoxWrapper}
      {...checkProps}
      userOnChange={onChange}
      userOnBlur={onBlur}
      trueValue={trueValue}
      falseValue={falseValue}
    />
  )
}

export default FormCheckBox
