import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, Link, withRouter, NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Dimmer, Loader, Grid, Container, Menu, Dropdown, Responsive } from 'semantic-ui-react'

import config from '../config/';
import appActions from './actions'
import AppContext from './context'
import dialogActions from '../dialogs/actions'

import { Modals, Notifications } from '../dialogs/';

import accessActions from '../views/access/actions'
import { getAccessUser } from '../views/access/selectors'
import { getUser } from '../model/selectors'

import '../scss/index.scss';

const AccessPage = lazy(() => import('../views/access'));
const ConfirmPage = lazy(() => import('../views/access/confirm'));
const AccountPage = lazy(() => import('../views/account'));

const CalendarPage = lazy(() => import('../views/calendar'));
const PlannerPage = lazy(() => import('../views/calendar/planner'))
const TimelinePage = lazy(() => import('../views/calendar/timeline'))
const AgendaPage = lazy(() => import('../views/calendar/agenda'));
const RulesPage = lazy(() => import('../views/rules'));
const DefinitionsPage = lazy(() => import('../views/definitions'));

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialUrl: props.history.location.pathname,
    }
  }
  componentDidMount() {
    this.props.accessCheck();
  }
  componentDidUpdate( prevProps ) {
    if ( !!this.props.user !== !!prevProps.user )
    {
      if ( this.props.user )
      {
        // Logged in
        this.props.history.replace( this.state.initialUrl )
      }
      else
      {
        // Logged out
        this.setState({ initialUrl: '/' }, ()=>this.props.history.replace( this.state.initialUrl ) )
      }
    }
  }
  render() {
    const context = {
    };
    // console.log( "App", this.props );
    const noUser = !this.props.user
    const pendingUser = this.props.user && this.props.user.UserState === 'pending'
    const confirmedUser = this.props.user && this.props.user.UserState === 'confirmed'
    const activeUser = this.props.user && this.props.user.UserState === 'active'
    const suspendedUser = this.props.user && this.props.user.UserState === 'suspended'
    const superUser = this.props.user && this.props.user.UserId <= 2
    return(
      <AppContext.Provider value={context}>
        <Grid padded="horizontally">
          <Helmet defaultTitle={config.TITLE} titleTemplate={config.TITLE+" | %s"}/>
          {(confirmedUser || activeUser) &&
          <Container className="header" fluid>
            <Menu fluid fixed="top" inverted>
              <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <Menu.Item as={Link} to={"/"} header>{config.TITLE}</Menu.Item>
              </Responsive>
              <Menu.Item as={NavLink} to={"/calendar"}>Calendar</Menu.Item>
              <Menu.Item as={NavLink} to={"/timeline"}>Timeline</Menu.Item>
              <Menu.Item as={NavLink} to={"/planner"}>Planner</Menu.Item>
              {superUser &&
              <Menu.Item as={NavLink} to={"/agenda"}>Agenda</Menu.Item>
              }
              {superUser &&
              <Menu.Item as={NavLink} to={"/rules"}>Rules</Menu.Item>
              }
              {superUser &&
              <Menu.Item as={NavLink} to={"/account"}>Account</Menu.Item>
              }
              <Dropdown item simple icon="bars" style={{marginLeft:'auto'}}>
                <Dropdown.Menu direction="left">
                  <Dropdown.Item icon="info" content="About" onClick={()=>this.props.aboutModalOpen()}/>
                  {superUser &&
                  <Dropdown.Item icon="configure" content="Config" onClick={()=>this.props.configModalOpen()}/>
                  }
                  <Dropdown.Item icon="sign-out" content="Logout" onClick={()=>this.props.accessLogout()}/>
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </Container>
          }
          <Container className="app" fluid>
            <Notifications/>
            {(noUser || pendingUser) &&
            <Switch>
              <Route exact path="/login">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <AccessPage/>
                </Suspense>
              </Route>
              <Route exact path="/confirm/:code?">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <ConfirmPage/>
                </Suspense>
              </Route>
              <Route exact path="/">
                <Redirect to={"/login"}/>
              </Route>
              <Route>
                <Redirect to={"/"}/>
              </Route>
            </Switch>
            }
            {confirmedUser &&
            <Switch>
              <Route exact path="/account">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <AccountPage/>
                </Suspense>
              </Route>
              <Route exact path="/">
                <Redirect to={"/account"}/>
              </Route>
              <Route>
                <Redirect to={"/"}/>
              </Route>
            </Switch>
            }
            {activeUser &&
            <Switch>
              <Route exact path="/calendar">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <CalendarPage/>
                </Suspense>
              </Route>
              <Route exact path="/planner">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <PlannerPage/>
                </Suspense>
              </Route>
              <Route exact path="/timeline">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <TimelinePage/>
                </Suspense>
              </Route>
              {superUser &&
              <Route exact path="/agenda">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <AgendaPage/>
                </Suspense>
              </Route>
              }
              {superUser &&
              <Route exact path="/rules">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <RulesPage/>
                </Suspense>
              </Route>
              }
              {superUser &&
              <Route exact path="/definitions">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <DefinitionsPage/>
                </Suspense>
              </Route>
              }
              {superUser &&
              <Route exact path="/account">
                <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
                  <AccountPage/>
                </Suspense>
              </Route>
              }
              <Route exact path="/">
                <Redirect to={"/calendar"}/>
              </Route>
              <Route>
                <Redirect to={"/"}/>
              </Route>
            </Switch>
            }
            <Modals/>
          </Container>
        </Grid>
      </AppContext.Provider>
    )
  }
}

export default withRouter(connect(store=>({
  user: getUser(store),
}),{
  ...appActions,
  ...dialogActions,
  ...accessActions,
})(App));
