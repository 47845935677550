import merge from 'deepmerge'

const baseState = {
  loaded: false,
  invalid: true,
  filter: {
    local: false,
    date: {
    },
    search: {
    },
    filter: {
    },
    sort : {
    },
    paging: {
    size: 0,
    current: 1,
    total: 0,
    },
  },
  records: [],
  record: null,
};

const createState = ( customState={} ) => {
  return merge( baseState, customState )
}

export default createState