import produce from 'immer'
import moment from 'moment'

import * as constants from './constants'
import api from '../../api'

const URL_BASE = 'schools'

export const expandRecord = ( record, schoolYearPatterns ) => {
  console.log( "Expand Record In", record, schoolYearPatterns )
  const newRecord = produce( record, draftRecord => {
    const schoolYearPattern = schoolYearPatterns.find( pattern => pattern.SchoolYearPatternId===draftRecord.SchoolYearPatternId )
    if ( !schoolYearPattern )
    {
      console.error( "Can't find school year pattern "+draftRecord.SchoolYearPatternId )
      return
    }
    draftRecord.yearPattern = schoolYearPattern
    const thisMonth = parseInt(moment().month(), 10 )
    const thisYear = parseInt(moment().year(), 10 ) - (thisMonth <= 8 ? 2 : 1)
    const termYears = [...Array(4)].map( ( value, index ) => (thisYear+index)+'/'+(thisYear+index+1) )
    draftRecord.years.forEach( year => {
      year.dates = {}
      termYears.forEach( termYear => {
        year.dates[termYear] = {
          terms: {},
          holidays: {},
        }
        for ( let i = 0; i < parseInt(schoolYearPattern.SchoolYearPatternTerms,10); i++ )
        {
          const termPattern = schoolYearPattern.terms[i]
          year.dates[termYear].terms[termPattern.SchoolYearTermNum] = {
            num: termPattern.SchoolYearTermNum,
            name: termPattern.SchoolYearTermName,
            breaks: {},
            insets: {},
            termPattern,
          }
        }
      })
      let lastTerm = null
      year.terms.forEach( term => {
        if ( year.dates[term.SchoolTermYear] !== undefined )
        {
          year.dates[term.SchoolTermYear].terms[term.SchoolTermNum].startDate = term.SchoolTermStartDate
          year.dates[term.SchoolTermYear].terms[term.SchoolTermNum].endDate = term.SchoolTermEndDate
          //console.log( "t", JSON.stringify(term) )
          //console.log( "lt", JSON.stringify(lastTerm) )
          if ( lastTerm )
          {
            year.dates[lastTerm.SchoolTermYear].holidays[lastTerm.SchoolTermNum] = {
              num: lastTerm.SchoolTermNum,
              name: year.dates[lastTerm.SchoolTermYear].terms[lastTerm.SchoolTermNum].termPattern.SchoolYearTermHolidayName,
              type: 'end-term',
              startDate: moment(lastTerm.SchoolTermEndDate).add(1,'d').format("YYYY-MM-DD"),
              endDate: moment(term.SchoolTermStartDate).subtract(1,'d').format("YYYY-MM-DD"),
            }
          }
          lastTerm = term
        }
      })
      year.breaks.forEach( termBreak => {
        if ( year.dates[termBreak.SchoolTermYear] !== undefined )
        {
          const breakInfo = year.dates[termBreak.SchoolTermYear].terms[termBreak.SchoolTermNum].termPattern.breaks.find( breakX => breakX.SchoolYearTermBreakNum === termBreak.SchoolTermBreakNum)
          if ( !breakInfo )
          {
            //console.log( year.dates[termBreak.SchoolTermYear].terms[termBreak.SchoolTermNum].termPattern.breaks )
            console.error( "Can't find school year term break "+termBreak.SchoolTermBreakNum )
            return
          }
          switch ( breakInfo.SchoolYearTermBreakType )
          {
            case 'half-term' :
            {
              year.dates[termBreak.SchoolTermYear].terms[termBreak.SchoolTermNum].breaks[termBreak.SchoolTermBreakNum] = {
                num: termBreak.SchoolTermBreakNum,
                name: breakInfo.SchoolYearTermBreakName,
                type: breakInfo.SchoolYearTermBreakType,
                startDate: termBreak.SchoolTermBreakStartDate,
                endDate: termBreak.SchoolTermBreakEndDate,
              }
              break;
            }
            case 'inset' :
            {
              year.dates[termBreak.SchoolTermYear].terms[termBreak.SchoolTermNum].insets[termBreak.SchoolTermBreakNum] = {
                //num: termBreak.SchoolTermBreakNum,
                name: breakInfo.SchoolYearTermBreakName,
                type: breakInfo.SchoolYearTermBreakType,
                startDate: termBreak.SchoolTermBreakStartDate,
                endDate: termBreak.SchoolTermBreakEndDate,
              }
              break;
            }
          }
        }
      })
      delete year.terms
      delete year.breaks
    })
  })
  console.log( "Expand Record Out", newRecord )
  return newRecord
}

export const compactRecord = ( record ) => {
  console.log( "Compacting record", record );
  const newRecord = produce( record, draftRecord => {
    draftRecord.years.forEach( ( schoolYear, yearIndex ) => {
      draftRecord.years[yearIndex].days.forEach( (day,dayIndex) => {
        draftRecord.years[yearIndex].days[dayIndex].SchoolYearId = schoolYear.SchoolYearId
      })
      draftRecord.years[yearIndex].terms = []
      draftRecord.years[yearIndex].breaks = []
      Object.keys(draftRecord.years[yearIndex].dates).forEach( termYear => {
        Object.keys(draftRecord.years[yearIndex].dates[termYear].terms).forEach( termNum => {
          const termIndex = draftRecord.years[yearIndex].terms.findIndex( term => term.SchoolTermYear === termYear && term.SchoolTermNum === termNum )
          if ( termIndex >= 0 )
          {
            draftRecord.years[yearIndex].terms[termIndex].SchoolYearId = schoolYear.SchoolYearId;
            draftRecord.years[yearIndex].terms[termIndex].SchoolTermStartDate = draftRecord.years[yearIndex].dates[termYear].terms[termNum].startDate
            draftRecord.years[yearIndex].terms[termIndex].SchoolTermEndDate = draftRecord.years[yearIndex].dates[termYear].terms[termNum].endDate
          }
          else if ( draftRecord.years[yearIndex].dates[termYear].terms[termNum].startDate && draftRecord.years[yearIndex].dates[termYear].terms[termNum].endDate )
          {
            draftRecord.years[yearIndex].terms.push({
              SchoolId: draftRecord.SchoolId,
              SchoolYearId: schoolYear.SchoolYearId,
              SchoolTermYear: termYear,
              SchoolTermNum: termNum,
              SchoolTermName: draftRecord.years[yearIndex].dates[termYear].terms[termNum].name,
              SchoolTermStartDate: draftRecord.years[yearIndex].dates[termYear].terms[termNum].startDate,
              SchoolTermEndDate: draftRecord.years[yearIndex].dates[termYear].terms[termNum].endDate,
            })
          }
          Object.keys(draftRecord.years[yearIndex].dates[termYear].terms[termNum].breaks).forEach( breakNum => {
            const breakIndex = draftRecord.years[yearIndex].breaks.findIndex( termBreak => termBreak.SchoolTermYear === termYear && termBreak.SchoolTermNum === termNum && termBreak.SchoolTermBreakNum === breakNum )
            if ( breakIndex >= 0 )
            {
              draftRecord.years[yearIndex].breaks[breakIndex].SchoolYearId = schoolYear.SchoolYearId;
              draftRecord.years[yearIndex].breaks[breakIndex].SchoolTermBreakStartDate = draftRecord.years[yearIndex].dates[termYear][termNum].breaks[breakNum].startDate
              draftRecord.years[yearIndex].breaks[breakIndex].SchoolTermBreakEndDate = draftRecord.years[yearIndex].dates[termYear][termNum].breaks[breakNum].endDate
            }
            else if ( draftRecord.years[yearIndex].dates[termYear].terms[termNum].breaks[breakNum].startDate && draftRecord.years[yearIndex].dates[termYear].terms[termNum].breaks[breakNum].endDate )
            {
              draftRecord.years[yearIndex].breaks.push({
                SchoolId: draftRecord.SchoolId,
                SchoolYearId: schoolYear.SchoolYearId,
                SchoolTermYear: termYear,
                SchoolTermNum: termNum,
                SchoolTermBreakNum: breakNum,
                SchoolTermBreakName: draftRecord.years[yearIndex].dates[termYear].terms[termNum].breaks[breakNum].name,
                SchoolTermBreakStartDate: draftRecord.years[yearIndex].dates[termYear].terms[termNum].breaks[breakNum].startDate,
                SchoolTermBreakEndDate: draftRecord.years[yearIndex].dates[termYear].terms[termNum].breaks[breakNum].endDate,
              })
            }
          })
        })
      })
      delete draftRecord.years[yearIndex].dates;
    })
  })
  return newRecord
}

export const getRecords = ( action=null ) => {
  const request = '/'+URL_BASE
  if ( action )
    return api.makeRequest({
      url: request,
    }, action );
  return api.makeBasicRequest( request );
}

export const getRecord = ( recordId, action=null ) => {
  const request = '/'+URL_BASE+'/'+recordId;
  if ( action )
    return api.makeRequest( request, action );
  return api.makeBasicRequest( request );
}

export const updateRecord = record => {
  console.log( "Updating school", record );
  if ( record.SchoolId )
    return api.makeRequest({
      method: 'put',
      url: '/'+URL_BASE+'/'+record.SchoolId,
      data: {
        record
      }},
      constants.RECORD_UPDATE
    )
  return api.makeRequest({
    method: 'post',
    url: '/'+URL_BASE,
    data: {
      record
    }},
    constants.RECORD_UPDATE
  )
}

export const deleteRecord = record => {
  console.log( "Deleting record", record );
  return api.makeRequest({
      method: 'delete',
      url: '/'+URL_BASE+'/'+record.SchoolId
    },
    constants.RECORD_DELETE,
  );
}
