import React, { Component } from 'react'
import * as Sentry from '@sentry/browser';

if ( process.env.NODE_ENV === 'production' ) {
  Sentry.init({
    dsn: "https://23d5515da04a457c99a5ad23326cfd9f@sentry.io/1415776"
  });
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    console.log( "Error", error )
    console.log( "ErrorInfo", errorInfo )
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
   if ( process.env.NODE_ENV === 'production' && this.state.error ) {
     //render fallback UI
     return (
       // eslint-disable-next-line jsx-a11y/anchor-is-valid
       <a href="#" onClick={() => Sentry.showReportDialog()}>Report feedback</a>
     );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
