import { createSelector } from 'reselect'

export const getDataModel = state => state.model

export const getAccount = createSelector(
  [ getDataModel ],
  dataModel => dataModel.account
)
export const getFamily = createSelector(
  [ getAccount ],
  account => account?account.family:{}
)
export const getMembers = createSelector(
  [ getFamily ],
  family => family?family.members:[]
)
export const getParents = createSelector(
  [ getMembers ],
  members => members && members.filter( member => member.FamilyMemberRole === 'parent' )
)
export const getCarers = createSelector(
  [ getMembers ],
  members => members && members.filter( member => member.FamilyMemberRole === 'parent' || member.FamilyMemberRole === 'carer' )
)
export const getChildren = createSelector(
  [ getMembers ],
  members => members && members.filter( member => member.FamilyMemberRole === 'child' )
)

export const getUser = createSelector(
  [ getDataModel ],
  dataModel => dataModel.user
)
export const getMember = createSelector(
  [ getMembers, getUser ],
  ( members, user ) => (members||[]).find( member=>member.UserId===user.UserId )
)

export const getSchools = createSelector(
  [ getFamily ],
  family => family.schools
)

export const getSchoolYearPatterns = createSelector(
  [ getDataModel ],
  dataModel => dataModel.schoolYearPatterns
)

export const getDefinitions = createSelector(
  [ getDataModel ],
  dataModel => dataModel.definitions
)

export const getRules = createSelector(
  [ getDataModel ],
  dataModel => dataModel.rules
)

export const isAdmin = createSelector(
    [ getUser ],
    user => user && user.UserRole === 'admin'
  )
export const canEdit = createSelector(
    [ getMember ],
    member => member && member.UserId <= "2"
  )
