import produce from 'immer'

import * as constants from '../../constants'
import * as ruleConstants from '../../entities/rule/constants'

const initialState = {
	invalid: true,
  records: [],
  record: null,
};

const reducer = function( state = initialState, action )
{
  return produce( state, newState => {
    switch( action.type ) {
      case ruleConstants.RECORDS_LOAD+constants.SUCCESS_SUFFIX :
        return produce( state, newState=>{
          newState.records = action.payload.records
        })
      case ruleConstants.ADD :
      case ruleConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX :
        return produce( state, newState=>{
          newState.record = action.payload
        })
      case ruleConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX :
        return produce( state, newState=>{
          newState.record = null
        })
      case ruleConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX :
      {
        return produce( state, newState=>{
          newState.record = null
        })
      }
      default :
    }
  })
}

export default reducer;
