import React from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'
import moment from 'moment'

import FormFieldDayPicker from '../dayPicker'

const FormFieldDayPickerWrapper = props => {
  //console.log( "FFdPw", props )
  const { userOnChange, userOnBlur, storeFormat, displayFormat, field: { name, value }, form: { touched: formTouched, errors, setFieldValue, setFieldTouched }, ...inputProps } = props
  const touched = getIn( formTouched, name )
  const pristine = !touched
  const error = getIn( errors, name )
  const inputValid = pristine || error===undefined
  const valueAsDate = value && moment(value,storeFormat).toDate()
  return(
    <FormFieldDayPicker
      name={name}
      value={valueAsDate}
      valid={inputValid}
      error={error}
      format={displayFormat}
      {...inputProps}
      onDayChange={value=>{
        setFieldValue(name,moment(value).format(storeFormat))
        setFieldTouched(name)
        userOnChange&&userOnChange(undefined,{name,value})
      }}
      onBlur={e=>{
        setFieldTouched(name)
        userOnBlur&&userOnBlur(e,{name})
      }}
    />
  )
}

const FormDayPicker = props => {
  const { onChange, onBlur, ...pickerProps } = props
  return(
    <Field
      component={FormFieldDayPickerWrapper}
      userOnChange={onChange}
      userOnBlur={onBlur}
      {...pickerProps}
    />
  )
}

FormDayPicker.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

FormDayPicker.defaultProps = {
  storeFormat: "YYYY-MM-DD",
  displayFormat: "DD/MM/YYYY",
};

export default FormDayPicker
