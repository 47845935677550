import { createActions } from 'redux-actions'
import * as constants from './constants';

const actions = createActions({
  [constants.RECORD_LOAD]: null,
  [constants.RECORD_UPDATE]: record => ( record ),
  [constants.RECORD_DELETE]: record  => ( record ),
  [constants.ADD]: user => ( user ),
  [constants.EDIT]: user => ( user ),
  [constants.CANCEL]: null,
  [constants.DELETE]: user => ( user ),
  [constants.INVITE]: user => ( user ),
  [constants.SEND_INVITE]: details => ( details ),
});

export default actions
