import { put, call, apply, select, all } from 'redux-saga/effects'

import api from '../api/'
import modelActions from '../model/actions'

const modelKeys = {
  base: {
    apiCall: 'getBase',
    spread: true,
    checkKey: 'currencies',
  },
  account: {
    apiCall: 'getAccount',
    spread: false,
    checkKey: 'account',
  },
  prefs: {
    apiCall: 'getPrefs',
    spread: false,
    checkKey: 'prefs',
  },
  familyAttrs: {
    apiCall: 'getFamilyAttrs',
    spread: true,
    checkKey: 'family-attrs',
  },
  family: {
    apiCall: 'getFamily',
    spread: false,
    checkKey: 'family',
  },
  memberAttrs: {
    apiCall: 'getMemberAttrs',
    spread: true,
    checkKey: 'member-attrs',
  },
  members: {
    apiCall: 'getMembers',
    spread: false,
    checkKey: 'members',
  },
  schoolAttrs: {
    apiCall: 'getSchoolAttrs',
    spread: true,
    checkKey: 'school-attrs',
  },
  schools: {
    apiCall: 'getSchools',
    spread: false,
    checkKey: 'schools',
  },
  definitions: {
    apiCall: 'getDefinitions',
    spread: false,
    checkKey: 'definitions',
  },
  eventAttrs: {
    apiCall: 'getEventAttrs',
    spread: true,
    checkKey: 'event-attrs',
  },
  events: {
    apiCall: 'getEvents',
    spread: false,
    checkKey: 'events',
  },
  periodAttrs: {
    apiCall: 'getPeriodAttrs',
    spread: true,
    checkKey: 'period-attrs',
  },
  ruleAttrs: {
    apiCall: 'getRuleAttrs',
    spread: true,
    checkKey: 'rule-attrs',
  },
  rules: {
    apiCall: 'getRules',
    spread: false,
    checkKey: 'rules',
  },
}

const getModelData = (state) => state.model

export function* fetchModelData( keys ) {
  try {
    const modelData = yield select(getModelData)
    let requestKeys = [];
    let apiRequests = [];
    keys.forEach( key => {
      if ( !modelKeys[key] )
      {
        console.warn( "No api request for model key "+key );
        return
      }
      const keyData = modelKeys[key];
      if ( keyData.spread )
      {
        if ( modelData[keyData.checkKey] === undefined )
        {
          requestKeys.push( key )
          apiRequests.push( apply( api, modelKeys[key].apiCall ) )
        }
        else
          console.log( "ModelData "+key+" already loaded" );
      }
      else
      {
        if ( modelData[key] === undefined )
        {
          requestKeys.push( key )
          apiRequests.push( apply( api, modelKeys[key].apiCall ) )
        }
        else
          console.log( "ModelData "+key+" already loaded" );
      }
    })
    const requests = yield all( apiRequests );
    let newModelData = {};
    requestKeys.forEach( ( key, index ) => {
      const keyData = modelKeys[key];
      if ( keyData )
        if ( keyData.spread  )
          Object.keys(requests[index].data).forEach( subKey => 
            newModelData[subKey] = requests[index].data[subKey]
          )
        else
          newModelData[key] = requests[index].data
    })
    console.log( "newModelData", newModelData )
    if ( Object.keys(newModelData).length > 0 )
      yield put( modelActions.modelDataUpdate( newModelData ) )
  } catch (e) {
    console.error( e )
  }
}

export function* fetchDependentData( modelData ) {
  yield call( fetchModelData, modelData )
}

export function* fetchRecords( apiCall ) {
  try {
    return yield call( apiCall )
  } catch (e) {
    console.error( e )
  }
}

export function* fetchFilteredRecords( state, apiCall, action ) {
  let parms = [];
  if ( !state.filter.local )
  {
    if ( state.filter.paging.current )
      parms.push( 'page.num='+state.filter.paging.current );
    if ( state.filter.paging.size )
      parms.push( 'page.size='+state.filter.paging.size );
    if ( state.filter.sort.col )
      parms.push( 'sort.'+state.filter.sort.col+'='+(state.filter.sort.asc?'a':'d') );
    Object.keys(state.filter.search).forEach( field => {
      if ( state.filter.search[field] !== "" && state.filter.search[field] !== null && state.filter.search[field] !== undefined  )
        parms.push( 'search.'+field+'='+encodeURIComponent(state.filter.search[field]) );
    })
    Object.keys(state.filter.filter).forEach( field => {
      if ( state.filter.filter[field] !== "" && state.filter.filter[field] !== null && state.filter.filter[field] !== undefined  )
        parms.push( 'filter.'+field+'='+encodeURIComponent(state.filter.filter[field]) );
    })
    if ( state.filter.date.start )
      parms.push( 'date.start='+state.filter.date.start );
    if ( state.filter.date.end )
      parms.push( 'date.end='+state.filter.date.end );
  }
  try {
    console.log( "Parms", parms );
    return yield call( apiCall, parms, action )
    //const user = yield call(Api.fetchUser, action.payload.userId);
    //yield put({type: "USER_FETCH_SUCCEEDED", user: user});
  } catch (e) {
    console.error( e )
    //yield put({type: "USER_FETCH_FAILED", message: e.message});
  }
}

