import { delay, call, put, all, select, takeLatest } from 'redux-saga/effects'

import * as constants from '../../constants'
//import * as schoolConstants from '../../entities/school/constants'
//import * as schoolApi from '../../entities/school/api'
import * as definitionConstants from '../../entities/definition/constants'
import * as definitionApi from '../../entities/definition/api'
//import { getSchoolState, getDefinitionState } from './selectors'
import { fetchDependentData, fetchFilteredRecords } from '../../model/sagas'

function *fetchBaseData() {
  yield call( fetchDependentData, [ 'memberAttrs', 'account' ] )
  // Hack
  yield call( fetchDependentData, [ 'schools', 'definitions' ] )
}

/*
function* loadSchoolRecord( action ) {
  try {
    //yield call( fetchBaseData )
    yield call( [ schoolApi, 'getRecord' ], action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function *schoolRecordLoaded( action ) {
  const record = action.payload;
  yield put({type: schoolConstants.EDIT_MODAL_OPEN, payload: record});
}

function* updateSchoolRecord( action ) {
  try {
    yield call( [ schoolApi, 'updateRecord' ],  action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *schoolRecordUpdated( action ) {
  yield put({type: schoolConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadSchoolRecords, 0 );
}

function *deleteSchool( action ) {
  const record = action.payload;
  yield put({type: schoolConstants.DELETE_MODAL_OPEN, payload: record});
}

function* deleteSchoolRecord( action ) {
  try {
    yield call( [ schoolApi, 'deleteRecord' ], action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *schoolRecordDeleted( action ) {
  yield put({type: schoolConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadSchoolRecords, 0 )
}
*/

function *refreshRecords() {
  yield all([
    call( fetchBaseData ),
    //call( loadSchoolRecords, 0 ),
    //call( loadDefinitionRecords, 0 ),
  ])
}

function* loadDefinitionRecords( action ) {
  try {
    yield call( fetchBaseData )
  } catch (e) {
    console.error( e )
  }
}

function* updateDefinitionRecord( action ) {
  try {
    yield call( [ definitionApi, 'updateRecord' ],  action.payload )
  } catch (e) {
    console.error( e )
  }
}
/*
function *definitionRecordUpdated( action ) {
  yield put({type: definitionConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadDefinitionRecords, 0 );
}

function *deleteDefinition( action ) {
  const record = action.payload;
  yield put({type: definitionConstants.DELETE_MODAL_OPEN, payload: record});
}

function* deleteDefinitionRecord( action ) {
  try {
    yield call( [ definitionApi, 'deleteDefinition' ], action.payload.record )
  } catch (e) {
    console.error( e )
  }
}

function *definitionRecordDeleted( action ) {
  yield put({type: definitionConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadDefinitionRecords, 0 )
}
*/
export default function*() {
  //yield takeLatest( schoolConstants.RECORD_LOAD, loadSchoolRecord );
  //yield takeLatest( schoolConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX, schoolRecordLoaded );
  //yield takeLatest( schoolConstants.RECORD_UPDATE, updateSchoolRecord );
  //yield takeLatest( schoolConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, schoolRecordUpdated );
  //yield takeLatest( schoolConstants.RECORD_DELETE, deleteSchoolRecord );
  //yield takeLatest( schoolConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, schoolRecordDeleted );
  //yield takeLatest( schoolConstants.DELETE, deleteSchool );
  //yield takeLatest( definitionConstants.ADD, addDefinition );
  //yield takeLatest( definitionConstants.EDIT, editDefinition );
  yield takeLatest( definitionConstants.RECORD_LOAD, loadDefinitionRecords );
  yield takeLatest( definitionConstants.RECORD_UPDATE, updateDefinitionRecord );
  //yield takeLatest( definitionConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, definitionRecordUpdated );
  //yield takeLatest( definitionConstants.RECORD_DELETE, deleteDefinitionRecord );
  //yield takeLatest( definitionConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, definitionRecordDeleted );
  //yield takeLatest( definitionConstants.DELETE, deleteDefinition );
  //yield takeLatest( definitionConstants.CANCEL, cancelDefinition );
  yield takeLatest( constants.UPDATE_REFRESH, refreshRecords );
}
