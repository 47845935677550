import { createActions } from 'redux-actions'
import * as constants from '../constants';
import * as periodConstants from '../entities/period/constants';
import * as eventConstants from '../entities/event/constants';
import * as userConstants from '../entities/user/constants';
import * as ruleConstants from '../entities/rule/constants';

const actions = createActions({
  [constants.ABOUT_MODAL_OPEN]: null,
  [constants.ABOUT_MODAL_CLOSE]: null,
  [constants.CONFIG_MODAL_OPEN]: null,
  [constants.CONFIG_MODAL_CLOSE]: null,
  [eventConstants.EDIT_MODAL_OPEN]: event => event,
  [eventConstants.EDIT_MODAL_CLOSE]: null,
  [eventConstants.DELETE_MODAL_OPEN]: event => event,
  [eventConstants.DELETE_MODAL_CLOSE]: null,
  [periodConstants.EDIT_MODAL_OPEN]: period => period,
  [periodConstants.EDIT_MODAL_CLOSE]: null,
  [periodConstants.DELETE_MODAL_OPEN]: period => period,
  [periodConstants.DELETE_MODAL_CLOSE]: null,
  [userConstants.INVITE_MODAL_OPEN]: member => member,
  [userConstants.INVITE_MODAL_CLOSE]: null,
  [ruleConstants.EDIT_MODAL_OPEN]: rule => rule,
  [ruleConstants.EDIT_MODAL_CLOSE]: null,
  [ruleConstants.DELETE_MODAL_OPEN]: rule => rule,
  [ruleConstants.DELETE_MODAL_CLOSE]: null,
  [constants.UPDATE_NOTIFICATION_OPEN]: null,
  [constants.UPDATE_NOTIFICATION_CLOSE]: null,
  [constants.UPDATE_REFRESH]: type => type,
  [constants.ERROR_NOTIFICATION_OPEN]: message => message,
  [constants.ERROR_NOTIFICATION_CLOSE]: null,
});

export default actions
