import { call, put, select, takeLatest, delay } from 'redux-saga/effects'

import * as constants from '../constants'
import config from '../config/'
import api from '../api/'
import { getAccessUser } from '../views/access/selectors'

import actions from './actions'

const getAppState = (state) => state.app

function* checkUpdateTimes() {
  const accessUser = yield select(getAccessUser)
  if ( !accessUser )
      return;
  try {
    const appState = yield select(getAppState)
    const response = yield call( [ api, 'getLastUpdates' ] )
    if ( response.data.LatestVersion > appState.updates.LatestVersion )
      yield put({type: constants.UPDATE_NOTIFICATION_OPEN, payload: { type: 'app', message: "A new version has been installed. Please refresh the application to update." }});
    if ( response.data.Updated > appState.updates.Updated )
      yield put({type: constants.UPDATE_NOTIFICATION_OPEN, payload: { type: 'data', message: "Data changes have been made by another user. Please refresh the application to include these updates." }});
    yield put( actions.lastUpdated( response.data ) )
  } catch (e) {
    console.error( e )
  }
}

function* refreshApp( action ) {
  yield call( ()=>window.location.reload() )
}

function* displayError( action ) {
  console.log( "Displaying Error", action )
  if ( action.payload.response )
  {
    if ( action.payload.response.status >= 500 )
      yield put({type: constants.ERROR_NOTIFICATION_OPEN, payload: action.payload.message });
  }
  else
    yield put({type: constants.ERROR_NOTIFICATION_OPEN, payload: "General network error" });
}

export default function* saga() {
  // Wake up when user starts timer.
  yield takeLatest( constants.UPDATE_REFRESH, refreshApp );
  yield takeLatest( action => RegExp( constants.FAIL_SUFFIX+'$' ).test( action.type ), displayError );
  while( true ) {
    yield delay( config.UPDATE_INTERVAL )
    yield call( checkUpdateTimes )
  }
}
