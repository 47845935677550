import { createActions } from 'redux-actions'
import * as constants from './constants';

const actions = createActions({
  [constants.RECORDS_LOAD]: null,
  [constants.RECORD_LOAD]: id => ( id ),
  [constants.RECORD_UPDATE]: record => ( record ),
  [constants.RECORD_DELETE]: record  => ( record ),
  [constants.ADD]: period => ( period ),
  [constants.EDIT]: period => ( period ),
  [constants.CANCEL]: null,
  [constants.DELETE]: period => ( period ),
});

export default actions