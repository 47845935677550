import { createContext } from 'react'

const defaultContext = {
  hosts: {},
  host: '',
  hostName: '',
}

const AppContext = createContext( defaultContext )
export default AppContext
