import { combineReducers } from 'redux'

// Reducers
import access from './access/reducers'
import calendar from './calendar/reducers'
import rules from './rules/reducers'
import account from './account/reducers'

export default combineReducers({
  access,
  calendar,
  rules,
  account,
})
