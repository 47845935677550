import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { Button } from 'semantic-ui-react';
import { FormattedNumber, FormattedDate } from 'react-intl'
import moment from 'moment';

export function ucFirst( str ) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const round = ( number, precision=2 ) => {
  const divisor = Math.pow(10, precision || 0).toFixed(precision < 0 ? -precision : 0);
  return (Math.round(parseFloat(number) * divisor) / divisor).toFixed(precision);
}

export function LifecycleLog(WrappedComponent) {
  return class LoggedComponent extends WrappedComponent {
      _log( methodName, ...args ) {
        console.log(WrappedComponent.name + '::' + methodName, args);
      }
      constructor( props ) {
          super( props );
          this._log('constructor','props',props);
      }
      componentWillUpdate( nextProps, nextState ) {
          this._log('componentWillUpdate','nextProps',nextProps,'nextState',nextState);
          if ( super.componentWillUpdate )
            return super.componentWillUpdate( nextProps, nextState );
      }
      componentDidUpdate( prevProps, prevState ) {
          this._log('componentDidUpdate','prevProps',prevProps,'prevState',prevState);
          if ( super.componentDidUpdate )
            return super.componentDidUpdate( prevProps, prevState );
      }
      componentWillMount() {
          this._log('componentWillMount');
          if ( super.componentWillMount )
            return super.componentWillMount();
      }
      componentDidMount() {
          this._log('componentDidMount');
          if ( super.componentDidMount )
            return super.componentDidMount();
      }
      componentWillUnmount() {
          this._log('componentWillUnmount');
          if ( super.componentWillUnmount )
            return super.componentWillUnmount();
      }
      render() {
          this._log('render');
          if ( super.render )
            return super.render()
      }
  }
}

export const PageTitle = (props) => {
  let parts = [];
  for ( let i = 1; i < props.routes.length; i++ )
    if ( props.routes[i].name )
      parts.push( props.routes[i].name );
  const params = Object.values(props.params);
  if ( params.length > 0 )
    parts.push( params[0] );
  return(
      <Helmet>
        <title>{parts.join(' - ')}</title>
      </Helmet>
  );
}

export const DateTime = props => {
  return(
    <FormattedDate {...props}/>
  )
}

DateTime.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
};

DateTime.defaultProps = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
};

export const Numeric = props => {
  const { decimals, minDecimals, maxDecimals, ...numberProps } = props
  return(
    <FormattedNumber minimumFractionDigits={minDecimals||decimals} maximumFractionDigits={maxDecimals||decimals} {...numberProps}/>
  )
}

Numeric.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  decimals: PropTypes.number.isRequired,
  minDecimals: PropTypes.number,
  maxDecimals: PropTypes.number,
};

Numeric.defaultProps = {
  decimals: 0,
};

export const Money = props => {
  const { currency, ...numberProps } = props
  return(
    // eslint-disable-next-line react/style-prop-object
    <Numeric style="currency" currency={currency} {...numberProps}/>
  )
}

Money.propTypes = {
  currency: PropTypes.string.isRequired,
  //value: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  decimals: PropTypes.number.isRequired,
  minDecimals: PropTypes.number,
  maxDecimals: PropTypes.number,
};

Money.defaultProps = {
  currency: 'GBP',
  decimals: 2,
};

export const Percent = props => {
  return(
    // eslint-disable-next-line react/style-prop-object
    <Numeric style="percent" {...props}/>
  )
}

Percent.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  decimals: PropTypes.number.isRequired,
  minDecimals: PropTypes.number,
  maxDecimals: PropTypes.number,
};

Percent.defaultProps = {
  decimals: 2,
};

export const filterRecords = ( records, filter ) => {
  if ( !records )
    return { records: [], filter }
  let newFilter = Object.assign( {}, filter, {} );
  if ( records.length )
  {
    for ( let field in filter.search )
    {
      const searchText = filter.search[field];
      if ( searchText )
      {
        const regex = RegExp(searchText,'i');
        records = records.filter( record => regex.test(record[field]) );
      }
    }
    if ( filter.hideMatched )
      records = records.filter( record => !record.matched );
    newFilter.paging.total = records.length;
    if ( records.length )
    {
      if ( filter.sort.col )
      {
        const col = filter.sort.col;
        switch( typeof records[0][col] )
        {
          case 'number' :
            if ( filter.sort.asc )
              records = records.slice().sort( ( a, b ) => { return a[col]-b[col] } );
            else
              records = records.slice().sort( ( b, a ) => { return a[col]-b[col] } );
            break;
          default :
            if ( filter.sort.asc )
              records = records.slice().sort( ( a, b ) => { return a[col]<b[col]?-1:(a[col]>b[col]?1:0) } );
            else
              records = records.slice().sort( ( b, a ) => { return a[col]<b[col]?-1:(a[col]>b[col]?1:0) } );
        }
      }
      if ( records.length > filter.paging.size )
      {
        const startIndex = (filter.paging.current-1)*filter.paging.size;
        const endIndex = startIndex+filter.paging.size;
        records = records.slice( startIndex, endIndex );
      }
    }
  }
  return { records, filter: newFilter }
}

/*
export const bindActionTree = ( actions, dispatch ) => {
  return traverse(actions).map( function( arg ) {
    if ( typeof this.node === 'function' )
      return this.update( bindActionCreators( this.node, dispatch ) );
    return arg;
  });
}
*/
export const getDatesFromRange = range => {
  let now = moment();
  let start = null;
  let end = null;
  switch ( range )
  {
    case '' :
      start = null;
      end = null;
      break;
    case 'this-week' :
      end = now.endOf('day');
      start = now.clone().startOf('week');
      break;
    case 'last-week' :
      end = now.day(now.day()-7).endOf('week');
      start = end.clone().startOf('week');
      break;
    case 'this-month' :
      end = now.endOf('day');
      start = now.clone().startOf('month');
      break;
    case 'last-month' :
      end = now.date(1).subtract(1,'day').endOf('month');
      start = end.clone().startOf('month');
      break;
    case 'this-quarter' :
      end = now.endOf('day');
      start = now.clone().startOf('quarter');
      break;
    case 'last-quarter' :
      end = now.startOf('quarter').subtract(1,'day').endOf('quarter');
      start = end.clone().startOf('quarter');
      break;
    case 'this-year' :
      end = now.endOf('day');
      start = now.clone().startOf('year');
      break;
    case 'last-year' :
      end = now.startOf('year').subtract(1,'day').endOf('year');
      start = end.clone().startOf('year');
      break;
    case 'custom' :
    default :
      return undefined;
  }
  return { start: start?start.format('YYYY-MM-DD'):'', end: end?end.format('YYYY-MM-DD'):'' }
}

export const BaseButton = props => {
  return(
    <Button type="button" size="small" compact {...props}/>
  )
}

export const AddButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton positive title={title||"Add"} icon="plus" {...buttonProps}/>
  )
}

export const CopyButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton color="blue" title={title||"Copy"} icon="copy" {...buttonProps}/>
  )
}

export const EditButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton color="orange" title={title||"Edit"} icon="edit" {...buttonProps}/>
  )
}

export const DeleteButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton negative title={title||"Delete"} icon="trash" {...buttonProps}/>
  )
}

export const PrintButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton color="violet" title={title||"Delete"} icon="print" {...buttonProps}/>
  )
}

export const ReloadButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton secondary title={title||"Reload"} icon="refresh" {...buttonProps}/>
  )
}

export const MiscButton = props => {
  const { color, ...buttonProps } = props
  return(
    <BaseButton color="teal" {...buttonProps}/>
  )
}

export const SelectButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton circular toggle title={title||"Select"} icon="check" {...buttonProps}/>
  )
}

export const OkButton = props => {
  const { color, icon, ...buttonProps } = props
  return(
    <BaseButton color={color||"green"} icon={icon||"check"} {...buttonProps}/>
  )
}

export const UneditButton = props => {
  const { color, icon, ...buttonProps } = props
  return(
    <BaseButton color={color||"yellow"} icon={icon||"close"} {...buttonProps}/>
  )
}

export const SaveButton = props => {
  const { icon, content, ...buttonProps } = props
  return(
    <Button type="button" primary icon={icon||"disk"} content={content||"Save"} {...buttonProps}/>
  )
}

export const CancelButton = props => {
  const { icon, content, ...buttonProps } = props
  return(
    <Button type="button" secondary icon={icon||"cancel"} content={content||"Cancel"} {...buttonProps}/>
  )
}
