import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'

const FormFieldStatic = props => {
  const { prompt, value, children, ...fieldProps } = props
  return(
    <Form.Field
      {...fieldProps}
      className="static"
    >
      {prompt!==undefined &&
      <label>{prompt?prompt:'\u2003'}</label>
      }
      <span>{value?value:children}</span>
    </Form.Field>
  )
}

export default FormFieldStatic
