import { createSelector } from 'reselect'

export const getViewState = state => state.views.access

export const getProgress = createSelector(
  [ getViewState ],
  viewState => viewState.progress
)

export const getLoginMessage = createSelector(
  [ getViewState ],
  viewState => viewState.loginMessage
)

export const getRegisterMessage = createSelector(
  [ getViewState ],
  viewState => viewState.registerMessage
)

export const getConfirmMessage = createSelector(
  [ getViewState ],
  viewState => viewState.confirmMessage
)

export const getAccessUser = createSelector(
  [ getViewState ],
  viewState => viewState.user
)
