import * as constants from '../constants'
import config from '../config'
import moment from 'moment'
import axios from 'axios'

class Api {
  constructor() {
    if ( !Api.instance ) {
      this.axiosInstance = axios.create({
        baseURL: config.API_BASE,
        responseType: 'json',
      });
      // Add a response interceptor
      this.axiosInstance.interceptors.response.use(function (response) {
        // Do something with response data
        console.log( "RESP", response )
        if ( response.status === 403 )
        {
          // Redirect to login
          //if ( this.store )
          //{
            //this.store.dispatch
          //}
        }
        return response;
      }, function (error) {
        // Do something with response error
        return error;
      });
      this._currentRequests = {};
      Api.instance = this;
    }
    return Api.instance;
  }

  attach( store ) {
    this.store = store;
  }

  makeBasicRequest( request ) {
    console.log( "Basic Request", request );
    return this.axiosInstance.request( request )
  }

  makeStaticRequest( request, actionType, dataKey ) {
    //return dispatch => {
      // Dispatch the action for telling our reducer
      // that the API person is in progress
      if ( this.store[dataKey] )
      {
        return new Promise( (resolve,reject) => {
          resolve( this.store[dataKey] );
        })
        .then( ( response ) => {
          this.store.dispatch({
            type: `${actionType}${constants.SUCCESS_SUFFIX}`,
            payload: response
          });
          return( response );
        });
      }
      else if ( this._currentRequests[dataKey] )
      {
        return this._currentRequests[dataKey];
      }
      else
      {
        this.store.dispatch({type: `${actionType}${constants.PENDING_SUFFIX}`});
        return this._currentRequests[dataKey] = this.axiosInstance.request( request )
        .then( response => {
          delete this._currentRequests[dataKey];
          this.store.dispatch({
            type: `${actionType}${constants.SUCCESS_SUFFIX}`,
            payload: response
          });
          return( response );
        })
        .catch( error => {
          delete this._currentRequests[dataKey];
          this.store.dispatch({
            type: `${actionType}${constants.FAIL_SUFFIX}`,
            error: true,
            payload: error,
          });
          return( Promise.reject( error ) );
        });
      }
    //};
  }

  makeRequest( request, actionType ) {
    console.log( "Request", request, actionType );
    this.store.dispatch({type: `${actionType}${constants.PENDING_SUFFIX}`});
    return this.axiosInstance.request( request )
      .then( response => {
        this.store.dispatch({
          type: `${actionType}${constants.SUCCESS_SUFFIX}`,
          payload: response.data
        });
        return response
      })
      .catch( error => {
        console.error( error );
        this.store.dispatch({
          type: `${actionType}${constants.FAIL_SUFFIX}`,
          error: true,
          payload: error,
        });
        return error
      }
    );
  }

  getBase() {
    const request = '/base';
    return this.makeBasicRequest( request );
  }

  getAccount() {
    const request = '/account'
    return this.makeBasicRequest( request )
  }

  getPrefs() {
    const request = '/prefs'
    return this.makeBasicRequest( request )
  }

  getFamilyAttrs() {
    const request = '/family-attrs';
    return this.makeBasicRequest( request );
  }

  getFamily() {
    const request = '/family'
    return this.makeBasicRequest( request );
  }

  getMemberAttrs() {
    const request = '/member-attrs';
    return this.makeBasicRequest( request );
  }

  getMembers() {
    const request = '/members'
    return this.makeBasicRequest( request );
  }

  getEventAttrs() {
    const request = '/event-attrs';
    return this.makeBasicRequest( request );
  }

  getEvents() {
    const request = '/events';
    return this.makeBasicRequest( request );
  }

  getPeriodAttrs() {
    const request = '/period-attrs';
    return this.makeBasicRequest( request );
  }

  getSchoolAttrs() {
    const request = '/school-attrs';
    return this.makeBasicRequest( request );
  }

  getSchools() {
    const request = '/schools'
    return this.makeBasicRequest( request );
  }

  getDefinitions() {
    const request = '/definitions'
    return this.makeBasicRequest( request );
  }

  getRuleAttrs() {
    const request = '/rule-attrs';
    return this.makeBasicRequest( request );
  }

  getRules() {
    const request = '/rules'
    return this.makeBasicRequest( request );
  }

  getTags( startDate, endDate ) {
    let request = '/tags'
    request += '?start='+moment(startDate).format( "YYYY-MM-DD" )
    request += '&end='+moment(endDate).format( "YYYY-MM-DD" )
    return this.makeBasicRequest( request );
  }

/*
  searchManufacturers( search, limit=null ) {
    let request = '/manufacturers';
    let params = [];
    if ( search )
      params.push( 'search.Company='+search )
    if ( limit )
      params.push( 'page.num=1&page.size='+limit )
    if ( params.length )
      request += '?'+params.join('&');
    return this.makeBasicRequest( request );
  }
*/
  getLastUpdates() {
    return this.makeBasicRequest( '/updates' );
  }
}

const api = new Api();
//Object.freeze(api);

export default api;
