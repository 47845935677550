import produce from 'immer'
import * as constants from '../constants'
import * as periodConstants from '../entities/period/constants';
import * as eventConstants from '../entities/event/constants';
import * as userConstants from '../entities/user/constants';
import * as ruleConstants from '../entities/rule/constants';

const initialState = {
  aboutModal: {
    open: false,
  },
  configModal: {
    open: false,
  },
  editEventModal: {
    open: false,
    event: null,
  },
  deleteEventModal: {
    open: false,
    event: null,
  },
  editPeriodModal: {
    open: false,
    period: null,
  },
  deletePeriodModal: {
    open: false,
    period: null,
  },
  inviteUserModal: {
    open: false,
    member: null,
  },
  editRuleModal: {
    open: false,
    rule: null,
  },
  deleteRuleModal: {
    open: false,
    rule: null,
  },
  updateNotification: {
    open: false,
    type: null,
    message: null,
  },
  errorNotification: {
    open: false,
    message: null,
  },
};

const dialogsReducer = function( state = initialState, action )
{
  switch( action.type )
  {
    case constants.CONFIG_MODAL_OPEN :
      return produce( state, newState=>{
        newState.configModal = {
          open: true,
        }
      })
    case constants.CONFIG_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.configModal = {
          open: false,
        }
      })

    case constants.ABOUT_MODAL_OPEN :
      return produce( state, newState=>{
        newState.aboutModal = {
          open: true,
        }
      })
    case constants.ABOUT_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.aboutModal = {
          open: false,
        }
      })

    case eventConstants.EDIT_MODAL_OPEN :
      return produce( state, newState=>{
        newState.editEventModal = {
          open: true,
          event: action.payload.event,
        }
      })
    case eventConstants.EDIT_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.editEventModal = {
          open: false,
          event: null,
        }
      })
    case eventConstants.DELETE_MODAL_OPEN :
      return produce( state, newState=>{
        newState.deleteEventModal = {
          open: true,
          event: action.payload.event,
        }
      })
    case eventConstants.DELETE_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.deleteEventModal = {
          open: false,
          event: null,
        }
      })

    case periodConstants.EDIT_MODAL_OPEN :
      return produce( state, newState=>{
        newState.editPeriodModal = {
          open: true,
          period: action.payload.period,
        }
      })
    case periodConstants.EDIT_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.editPeriodModal = {
          open: false,
          period: null,
        }
      })
    case periodConstants.DELETE_MODAL_OPEN :
      return produce( state, newState=>{
        newState.deletePeriodModal = {
          open: true,
          period: action.payload.period,
        }
      })
    case periodConstants.DELETE_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.deletePeriodModal = {
          open: false,
          period: null,
        }
      })

      case userConstants.INVITE_MODAL_OPEN :
        return produce( state, newState=>{
          newState.inviteUserModal = {
            open: true,
            member: action.payload,
          }
        })
      case userConstants.INVITE_MODAL_CLOSE :
        return produce( state, newState=>{
          newState.inviteUserModal = {
            open: false,
            member: null,
          }
        })

    case ruleConstants.EDIT_MODAL_OPEN :
      return produce( state, newState=>{
        newState.editRuleModal = {
          open: true,
          rule: action.payload,
        }
      })
    case ruleConstants.EDIT_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.editRuleModal = {
          open: false,
          rule: null,
        }
      })
    case ruleConstants.DELETE_MODAL_OPEN :
      return produce( state, newState=>{
        newState.deleteRuleModal = {
          open: true,
          rule: action.payload,
        }
      })
    case ruleConstants.DELETE_MODAL_CLOSE :
      return produce( state, newState=>{
        newState.deleteRuleModal = {
          open: false,
          period: null,
        }
      })

    case constants.UPDATE_NOTIFICATION_OPEN :
      return produce( state, newState=>{
        newState.updateNotification = {
          open: true,
          type: action.payload.type,
          message: action.payload.message,
        }
      })
    case constants.UPDATE_NOTIFICATION_CLOSE :
    case constants.UPDATE_REFRESH :
      return produce( state, newState=>{
        newState.updateNotification = {
          open: false,
          type: null,
          message: null,
        }
      })
    /*case constants.ITEMS_UPDATED :
      return produce( state, newState=>{
        newState.updateNotification = {
          open: state.updateNotification.open,
          updateTime: action.payload.updatedAt,
        }
      })*/

    case constants.ERROR_NOTIFICATION_OPEN :
      return produce( state, newState=>{
        newState.errorNotification = {
          open: true,
          message: action.payload,
        }
      })
    case constants.ERROR_NOTIFICATION_CLOSE :
      return produce( state, newState=>{
        newState.errorNotification = {
          open: false,
          message: null,
        }
      })

    default:
  }
  return state
}

export default dialogsReducer;
