import React from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'

import FormFieldDropDown from '../dropdown'

const FormFieldDropDownWrapper = props => {
  //console.log( "FFDw", props )
  const { userOnChange, userOnBlur, field: { name, value }, form: { touched: formTouched, errors, setFieldValue, setFieldTouched }, ...inputProps } = props
  const touched = getIn( formTouched, name )
  const pristine = !touched
  const error = getIn( errors, name )
  const inputValid = pristine || error===undefined
  return(
    <FormFieldDropDown
      name={name}
      value={value}
      valid={inputValid}
      error={error}
      {...inputProps}
      onChange={(e,data)=>{
        setFieldValue(data.name,data.value)
        setFieldTouched(data.name)
        userOnChange&&userOnChange(e,data)
      }}
      onBlur={(e,data)=>{
        setFieldTouched(data.name)
        userOnBlur&&userOnBlur(e,data)
      }}
    />
  )
}

const FormDropDown = props => {
  const { onChange, onBlur, ...dropDownProps } = props
  return(
    <Field
      component={FormFieldDropDownWrapper}
      userOnChange={onChange}
      userOnBlur={onBlur}
      {...dropDownProps}
    />
  )
}

export default FormDropDown
