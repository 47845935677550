import React from 'react'
import { connect } from 'react-redux'
import { Modal, Header, Message, Button, Icon } from 'semantic-ui-react';
import moment from 'moment'

import config from '../config'
import dialogActions from './actions'
import { ConfirmModal } from '../components/modals'
//import ConfigModal from '../config/forms'
import { EditPeriodModal } from '../entities/period/forms'
import { EditEventModal } from '../entities/event/forms'
import periodActions from '../entities/period/actions'
import eventActions from '../entities/event/actions'
import { InviteUserModal } from '../entities/user/forms'
import { EditRuleModal } from '../entities/rule/forms'
import userActions from '../entities/user/actions'

const ModalsBase = ( props ) => {
  return (
    <div>
      <EditEventModal
        record={props.editEventModal.event}
        show={props.editEventModal.open}
        toggle={()=>props.editEventModalClose()}
        onChange={props.eventRecordUpdate}
        onDelete={()=>props.eventRecordDelete(props.editEventModal.event)}
      />
      <ConfirmModal
        show={props.deleteEventModal.open}
        title="Confirm Delete Event"
        message="Are you sure you want to delete this event?"
        onSubmit={()=>props.eventRecordDelete(props.deleteEventModal.event)}
        toggle={()=>props.deleteEventModalClose()}
      />
      <EditPeriodModal
        record={props.editPeriodModal.period}
        show={props.editPeriodModal.open}
        toggle={()=>props.editPeriodModalClose()}
        onChange={props.periodRecordUpdate}
        onDelete={()=>props.periodRecordDelete(props.editPeriodModal.period)}
      />
      <ConfirmModal
        show={props.deletePeriodModal.open}
        title="Confirm Delete Period"
        message="Are you sure you want to delete this period?"
        onSubmit={()=>props.periodRecordDelete(props.deletePeriodModal.period)}
        toggle={()=>props.deletePeriodModalClose()}
      />
      <InviteUserModal
        member={props.inviteUserModal.member}
        show={props.inviteUserModal.open}
        toggle={()=>props.inviteUserModalClose()}
        onChange={props.userInvite}
      />
      <EditRuleModal
        record={props.editRuleModal.rule}
        show={props.editRuleModal.open}
        toggle={()=>props.editRuleModalClose()}
        onChange={props.ruleRecordUpdate}
        onDelete={()=>props.ruleRecordDelete(props.editRuleModal.rule)}
      />
      <ConfirmModal
        show={props.deleteRuleModal.open}
        title="Confirm Delete Rule"
        message="Are you sure you want to delete this rule?"
        onSubmit={()=>props.ruleRecordDelete(props.deleteRuleModal.rule)}
        toggle={()=>props.deleteRuleModalClose()}
      />
      <Modal
        open={props.aboutModal.open}
        onClose={()=>props.aboutModalClose()}
        size="small"
      >
        <Header icon="browser" content="About"/>
        <Modal.Content>
          <h3>{config.TITLE}</h3>
          <p>Version v{config.VERSION+(process.env.NODE_ENV==='production'?'':'-dev')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={()=>props.aboutModalClose()}>
            <Icon name='checkmark' /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export const Modals = connect(store=>(store.dialogs),{
  ...dialogActions,
  ...periodActions,
  ...eventActions,
  ...userActions,
})(ModalsBase);

const NotificationsBase = ( props ) => {
  if ( props.updateNotification.open )
  {
    const updateTime = moment().format("HH:mm:ss")
    return (
      <Message success={props.updateNotification.type==='app'} warning={props.updateNotification.type==='data'} icon onDismiss={()=>props.updateNotificationClose()}>
        <Icon name="exclamation triangle"/>
        <Message.Content>
          <Message.Header>Update</Message.Header>
          {updateTime}: {props.updateNotification.message} <Button type="button" compact size="mini" onClick={()=>props.updateRefresh(props.updateNotification.type)} icon="refresh"/>
        </Message.Content>
      </Message>
    )
  }
  if ( props.errorNotification.open )
  {
    const updateTime = moment().format("HH:mm:ss")
    return (
      <Message error icon onDismiss={()=>props.errorNotificationClose()}>
        <Icon name="exclamation triangle"/>
        <Message.Content>
          <Message.Header>Error</Message.Header>
          {updateTime}: API Error - {props.errorNotification.message}
        </Message.Content>
      </Message>
    )
  }
  return null
}

export const Notifications = connect(store=>(store.dialogs),dialogActions)(NotificationsBase);
