const Config = {
  TITLE: 'Child Arrangements Calendar',
  API_BASE: '/api/v1',
  UPDATE_INTERVAL: 600*1000,
  NAME: process.env.REACT_APP_NAME,
  VERSION: process.env.REACT_APP_VERSION,
  DESCRIPTION: process.env.REACT_APP_DESCRIPTION,
}

export default Config;
