import React from 'react'
import PropTypes from 'prop-types'
import { Form, Message } from 'semantic-ui-react'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

moment.locale('en')
momentLocalizer()

const FormFieldDateTimePicker = props => {
  //console.log( "FFDTP", props )
  const { prompt, name, value, width, inline, required, nomessage, valid, error, ...pickerProps } = props
  return(
    <Form.Field width={width} error={!valid} inline={inline} required={required}>
      {prompt!==undefined &&
      <label>{prompt?prompt:'\u2003'}</label>
      }
      <DateTimePicker
        name={name}
        value={value}
        {...pickerProps}
      />
      {(!nomessage && !valid) &&
      <Message error={!valid}>{error}</Message>
      }
    </Form.Field>
  )
}

FormFieldDateTimePicker.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  error: PropTypes.string,
}

FormFieldDateTimePicker.defaultProps = {
  valid: true,
}

export default FormFieldDateTimePicker
