import { delay, call, put, all, select, takeLatest } from 'redux-saga/effects'

import * as constants from '../../constants'
import * as userConstants from '../../entities/user/constants'
import * as userApi from '../../entities/user/api'
import * as accountConstants from '../../entities/account/constants'
import * as accountApi from '../../entities/account/api'
import * as schoolConstants from '../../entities/school/constants'
import * as schoolApi from '../../entities/school/api'
import { fetchDependentData } from '../../model/sagas'

function* loadUser( action ) {
  try {
    yield call( fetchDependentData, [ 'prefs' ] )
    yield call( [ userApi, 'getRecord' ], action.type )
  } catch (e) {
    console.error( e )
  }
}

function *userLoaded( action ) {
  const record = action.payload;
}

function* updateUser( action ) {
  try {
    yield call( [ userApi, 'updateRecord' ],  action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *userUpdated( action ) {
  yield put({type: constants.MODEL_DATA_EXPIRE, payload: [ 'account' ] })
}

function* loadAccount( action ) {
  try {
    yield call( fetchDependentData, [ 'memberAttrs' ] )
    yield call( [ accountApi, 'getRecord' ], action.type )
  } catch (e) {
    console.error( e )
  }
}

function *accountLoaded( action ) {
  const record = action.payload;
}

function* updateAccount( action ) {
  try {
    yield call( [ accountApi, 'updateRecord' ],  action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *accountUpdated( action ) {
  yield put({type: constants.MODEL_DATA_EXPIRE, payload: [ 'account' ] })
}

function *inviteUser( action ) {
  const member = action.payload;
  yield put({type: userConstants.INVITE_MODAL_OPEN, payload: member });
}

function* sendUserInvite( action ) {
  try {
    yield call( [ userApi, 'sendInvite' ], action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *userInvitationSent( action ) {
  yield put({type: userConstants.INVITE_MODAL_CLOSE});
  yield call( [ accountApi, 'getRecord' ], action.type )
}

function* loadSchools( action ) {
  try {
    yield call( fetchDependentData, [ 'schoolAttrs' ] )
    yield call( [ schoolApi, 'getRecords' ], action.type )
  } catch (e) {
    console.error( e )
  }
}

function *schoolsLoaded( action ) {
  const records = action.payload;
}

function* updateSchool( action ) {
  try {
    const apiRecord = yield call( [ schoolApi, 'compactRecord' ], action.payload );
    yield call( [ schoolApi, 'updateRecord' ], apiRecord )
  } catch (e) {
    console.error( e )
  }
}

function *schoolUpdated( action ) {
  yield put({type: constants.MODEL_DATA_EXPIRE, payload: [ 'schools' ] })
}

export default function*() {
  yield takeLatest( userConstants.RECORD_LOAD, loadUser );
  yield takeLatest( userConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX, userLoaded );
  yield takeLatest( userConstants.RECORD_UPDATE, updateUser );
  yield takeLatest( userConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, userUpdated );
  yield takeLatest( userConstants.INVITE, inviteUser );
  yield takeLatest( userConstants.SEND_INVITE, sendUserInvite );
  yield takeLatest( userConstants.SEND_INVITE+constants.SUCCESS_SUFFIX, userInvitationSent );
  yield takeLatest( accountConstants.RECORD_LOAD, loadAccount );
  yield takeLatest( accountConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX, accountLoaded );
  yield takeLatest( accountConstants.RECORD_UPDATE, updateAccount );
  yield takeLatest( accountConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, accountUpdated );
  yield takeLatest( schoolConstants.RECORDS_LOAD, loadSchools );
  yield takeLatest( schoolConstants.RECORDS_LOAD+constants.SUCCESS_SUFFIX, schoolsLoaded );
  yield takeLatest( schoolConstants.RECORD_UPDATE, updateSchool );
  yield takeLatest( schoolConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, schoolUpdated );
  //yield takeLatest( schoolConstants.RECORD_LOAD, loadSchool );
  //yield takeLatest( schoolConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX, schoolLoaded );
  //yield takeLatest( userConstants.RECORD_DELETE, deleteRecord );
  //yield takeLatest( userConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, recordDeleted );
}