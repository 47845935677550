import React from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'
import moment from 'moment'

import FormFieldDateTimePicker from '../datetimePicker'

const FormFieldDateTimePickerWrapper = props => {
  //console.log( "FFDTPw", props )
  const { userOnChange, userOnBlur, storeFormat, displayFormat, field: { name, value }, form: { touched: formTouched, errors, setFieldValue, setFieldTouched }, ...pickerProps } = props
  const touched = getIn( formTouched, name )
  const pristine = !touched
  const error = getIn( errors, name )
  const inputValid = pristine || error===undefined
  const valueAsDate = value && moment(value,storeFormat).toDate()
  return(
    <FormFieldDateTimePicker
      name={name}
      value={valueAsDate}
      valid={inputValid}
      error={error}
      editFormat={storeFormat}
      format={displayFormat}
      {...pickerProps}
      parse={value=>moment(value).toDate()}
      onChange={value=>{
        setFieldValue(name,moment(value).format(storeFormat))
        setFieldTouched(name)
        userOnChange&&userOnChange(undefined,{name,value})
      }}
      onBlur={e=>{
        setFieldTouched(name)
        userOnBlur&&userOnBlur(e,{name})
      }}
    />
  )
}

const FormDateTimePicker = props => {
  const { onChange, onBlur, ...pickerProps } = props
  return(
    <Field
      component={FormFieldDateTimePickerWrapper}
      userOnChange={onChange}
      userOnBlur={onBlur}
      {...pickerProps}
    />
  )
}

FormDateTimePicker.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  error: PropTypes.string,
  storeFormat: PropTypes.string,
  displayFormat: PropTypes.string,
}

FormDateTimePicker.defaultProps = {
  displayFormat: "dd D MMM, YYYY h:mm A",
  timeFormat: "h:mm A",
  storeFormat: "YYYY-MM-DD HH:mm",
}

export default FormDateTimePicker
