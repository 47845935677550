import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Form } from 'semantic-ui-react';
import { Formik } from 'formik'
import { object, string, number, date } from 'yup';

import { EditModal } from '../../components/modals/'
import { FormInput, FormTextArea, FormDropDown, FormDateTimePicker } from '../../components/forms/formik/'
import { isAdmin, getDataModel, getParents, getChildren, canEdit } from '../../model/selectors'
import { ucFirst, MiscButton } from '../../utils'
import viewActions from './actions'

const periodSchema = object().shape({
  CarerMemberId:  number().required().label("Carer"),
  ChildMemberId:  number().required().label("Child"),
  PeriodStart:    date().required().label("Start"),
  PeriodEnd:      date().required().label("End"),
  PeriodType:     string().required().label("Type"),
  PeriodNotes:    string().nullable().label("Notes"),
});

class PeriodFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      periodTypes: [],
    }
  }
  componentDidMount() {
  }
  render() {
    const { values: record, dataModel } = this.props

    console.log( "RND", this.state, this.props )
    //const customers = (dataModel.customers || []).sort( ( a, b ) => a.Company < b.Company ? -1 : a.Company > b.Company ? 1 : 0 ).map( contact => ({ value: contact.ContactId, text: contact.Company }) )
    const periodTypes = this.state.periodTypes.concat(dataModel.periodTypes || []).map( periodType => ({ value: periodType, text: periodType }) )
    const parents = (this.props.parents || []).map( member => ({ value: member.MemberId, text: ucFirst(member.MemberFirstName) }) )
    const children = (this.props.children || []).map( member => ({ value: member.MemberId, text: ucFirst(member.MemberFirstName) }) )

    return (
      <Form success={this.props.valid} warning={this.props.warning} error={this.props.invalid}>
        <Segment attached="top">
          <Form.Group>
            <FormDropDown
              name="CarerMemberId"
              prompt="Carer"
              required
              selection
              options={parents}
              width={3}
              fluid
            />
            <FormDropDown
              name="ChildMemberId"
              prompt="Child"
              required
              selection
              options={children}
              width={3}
              fluid
            />
            <FormDateTimePicker
              name="PeriodStart"
              prompt="Start"
              displayFormat="ddd Do MMM, h:mma"
              timeFormat="h:mma"
              required
              time={record.PeriodHasTime!=="0"}
              width={5}
            />
            <FormDateTimePicker
              name="PeriodEnd"
              prompt="End"
              displayFormat="ddd Do MMM, h:mma"
              timeFormat="h:mma"
              required
              time={record.PeriodHasTime!=="0"}
              width={5}
            />
          </Form.Group>
          <Form.Group>
            <FormDropDown
              name="PeriodType"
              prompt="Type"
              required
              selection
              search
              allowAdditions
              onAddItem={(event,data)=>{this.setState({periodTypes:this.state.periodTypes.concat([data.value])})}}
              options={periodTypes}
              width={4}
              fluid
            />
            <FormInput
              name="PeriodAttrs"
              prompt="Attrs"
              width={6}
              fluid
              disabled
            />
          </Form.Group>
        </Segment>
        <Header block attached>
          Notes
        </Header>
        <Segment attached="bottom">
          <FormTextArea
            name="PeriodNotes"
          />
        </Segment>
      </Form>
    )
  }
}

const PeriodForm = connect(store=>({
  dataModel: getDataModel(store),
  parents: getParents( store ),
  children: getChildren( store ),
}),
  null
)(PeriodFormBase)

class EditPeriodModalBase extends Component {
  render() {
    console.log( "EEM", this.props )
    const { record, show, toggle } = this.props
    if ( !record )
      return null;
    const editing = record && record.PeriodId;
    const title = (editing?"Edit":"Create")+" Period"+(editing?(" "+record.PeriodId):"");
    return(
      <Formik
        initialValues={record}
        isInitialValid={record.PeriodId!==undefined}
        validationSchema={periodSchema}
        onSubmit={this.props.onChange}
        render={ props => {
          return(
            <EditModal
              show={show}
              toggle={toggle}
              title={title}
              canSubmit={()=>this.props.canEdit&&props.isValid}
              onSubmit={props.handleSubmit}
              extraControls={this.props.isAdmin && <MiscButton size="medium" floated="left" icon="redo" content="Generate" onClick={()=>this.props.periodRecordsGenerate(record.PeriodStart)}/>}
              onDelete={this.props.canEdit&&this.props.onDelete}
            >
              <PeriodForm {...props}/>
            </EditModal>
          )
        }}
      />
    )
  }
}

export const EditPeriodModal = connect( store => ({
  isAdmin: isAdmin( store ),
  canEdit: canEdit( store ),
}),
{
  ...viewActions
})(EditPeriodModalBase)
