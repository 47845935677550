const entity = 'EVENT'

export const STATE_INIT = entity+'_STATE_INIT'
export const RECORDS_LOAD = entity+'_RECORDS_LOAD'
export const RECORDS_PAGE = entity+'_RECORDS_PAGE'
export const RECORDS_SORT = entity+'_RECORDS_SORT'
export const RECORDS_SEARCH = entity+'_RECORDS_SEARCH'
export const RECORDS_FILTER = entity+'_RECORDS_FILTER'
export const RECORDS_DATE = entity+'_RECORDS_DATE'
export const RECORDS_GENERATE = entity+'_RECORDS_GENERATE'
export const RECORDS_RESET = entity+'_RECORDS_RESET'
export const RECORD_LOAD = entity+'_RECORD_LOAD'
export const RECORD_UPDATE = entity+'_RECORD_UPDATE'
export const RECORD_ASSIGN = entity+'_RECORD_ASSIGN'
export const RECORD_UNASSIGN = entity+'_RECORD_UNASSIGN'
export const RECORD_DELETE = entity+'_RECORD_DELETE'

export const ADD = 'ADD_'+entity
export const EDIT = 'EDIT_'+entity
export const CANCEL = 'CANCEL_'+entity
export const DELETE = 'DELETE_'+entity

export const EDIT_MODAL_OPEN = 'EDIT_'+entity+'_MODAL_OPEN'
export const EDIT_MODAL_CLOSE = 'EDIT_'+entity+'_MODAL_CLOSE'
export const DELETE_MODAL_OPEN = 'DELETE_'+entity+'_MODAL_OPEN'
export const DELETE_MODAL_CLOSE = 'DELETE_'+entity+'_MODAL_CLOSE'