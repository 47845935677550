import produce from 'immer'
import isEqual from 'lodash.isequal'
import LogRocket from 'logrocket'

import * as constants from '../../constants'
import * as viewConstants from './constants'

const initialState = {
  progress: false,
  loginMessage: null,
  registerMessage: null,
  confirmMessage: {},
  user: null,
};

export default ( state = initialState, action ) => {
  return produce( state, newState => {
    switch( action.type ) {
      case viewConstants.LOGIN:
      case viewConstants.LOGOUT:
      {
        newState.progress = true
        newState.loginMessage = null
        break
      }
      case viewConstants.LOGIN+constants.SUCCESS_SUFFIX :
      {
        const user = action.payload
        LogRocket.identify(user.UserName, {
          id: user.UserId,
          name: user.UserFullName,
          site: process.env.NODE_ENV === 'production' ? 'live' : 'dev',
        });
        // Fallthru
        // eslint-disable-next-line no-fallthrough
      }
      case viewConstants.CHECK+constants.SUCCESS_SUFFIX :
      case viewConstants.VALIDATE+constants.SUCCESS_SUFFIX :
      {
        newState.progress = false
        newState.loginMessage = null
        if ( !isEqual( newState.user, action.payload ) )
          newState.user = action.payload
        break
      }
      case viewConstants.LOGOUT+constants.SUCCESS_SUFFIX :
      case viewConstants.CHECK+constants.FAIL_SUFFIX :
      case viewConstants.VALIDATE+constants.FAIL_SUFFIX :
      {
        newState.progress = false
        newState.loginMessage = null
        newState.user = null
        break
      }
      case viewConstants.LOGIN+constants.FAIL_SUFFIX :
      {
        newState.progress = false
        newState.loginMessage = { type: 'error', title: "Login Failed", text: action.payload.response.statusText }
        break
      }
      case viewConstants.LOGOUT+constants.FAIL_SUFFIX :
      {
        newState.progress = false
        newState.loginMessage = null
        break
      }

      case viewConstants.REGISTER :
      {
        newState.progress = true
        newState.registerMessage = null
        break
      }
      case viewConstants.REGISTER+constants.SUCCESS_SUFFIX :
      {
        const user = action.payload
        LogRocket.identify(user.UserName, {
          id: user.UserId,
          name: user.UserFullName,
          site: process.env.NODE_ENV === 'production' ? 'live' : 'dev',
        });
        newState.progress = false
        newState.registerMessage = { type: 'success', title: "Registration Successful", text: "You have successfully registered. A confirmation email has been sent to you. Please check for further instructions." }
        if ( !isEqual( newState.user, action.payload ) )
          newState.user = action.payload
        break
      }
      case viewConstants.REGISTER+constants.FAIL_SUFFIX :
      {
        newState.progress = false
        newState.registerMessage = { type: 'error', title: "Registration Failed", text: action.payload.response.statusText }
        break
      }

      case viewConstants.CONFIRM :
      {
        newState.progress = true
        newState.confirmMessage = { type: null, title: "Account Confirmation", text: "Checking your account" }
        break
      }
      case viewConstants.CONFIRM+constants.SUCCESS_SUFFIX :
      {
        newState.progress = false
        newState.confirmMessage = {}
        newState.loginMessage = { type: 'success', title: "Confirmation Successful", text: "You have successfully confirmed your account. Please login to continue." }
        break
      }
      case viewConstants.CONFIRM+constants.FAIL_SUFFIX :
      {
        newState.progress = false
        newState.confirmMessage = { type: 'error', title: "Confirmation Failed", text: action.payload.response.statusText }
        break
      }

      default :
    }
  })
}
