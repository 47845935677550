import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form';

// Reducers
import app from '../app/reducers';
import views from '../views/reducers';
import api from '../api/reducers';
import model from '../model/reducers';
import dialogs from '../dialogs/reducers';

export default history => combineReducers({
  app,
  views,
  api,
  model,
  dialogs,
  form: formReducer,
  router: connectRouter(history),
})
