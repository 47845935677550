import produce from "immer"
import * as constants from '../constants'

const initialState = {
  loading: false,
};

const loadStartRegex = RegExp(`_LOAD${constants.PENDING_SUFFIX}$`);
const loadEndRegex = RegExp(`_LOAD(?:${constants.SUCCESS_SUFFIX}|${constants.FAIL_SUFFIX})$`);

export default ( state = initialState, action ) => {

  if ( loadStartRegex.test(action.type) )
    return produce( state, newState => {
      newState.loading = true
    })
  if ( loadEndRegex.test(action.type) )
    return produce( state, newState => {
      newState.loading = false
    })
  return state;
}
