import * as constants from './constants'
import api from '../../api'

const URL_BASE = 'account'

export const getRecord = ( action=null ) => {
  const request = '/'+URL_BASE;
  if ( action )
    return api.makeRequest( request, action );
  return api.makeBasicRequest( request );
}

export const updateRecord = record => {
  console.log( "Updating account", record );
  if ( record.family.FamilyId )
    return api.makeRequest({
      method: 'put',
      url: '/'+URL_BASE,
      data: {
        record
      }},
      constants.RECORD_UPDATE
    )
  return api.makeRequest({
    method: 'post',
    url: '/'+URL_BASE,
    data: {
      record
    }},
    constants.RECORD_UPDATE
  )
}

export const deleteRecord = record => {
  console.log( "Deleting account", record );
  return api.makeRequest({
      method: 'delete',
      url: '/'+URL_BASE
    },
    constants.RECORD_DELETE,
  );
}
