import React from 'react'
import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'

import FormFieldColorPicker from '../colorPicker'

const FormFieldColorPickerWrapper = props => {
  //console.log( "FFdPw", props )
  const { userOnChange, userOnBlur, field: { name, value }, form: { touched: formTouched, errors, setFieldValue, setFieldTouched }, ...inputProps } = props
  const touched = getIn( formTouched, name )
  const pristine = !touched
  const error = getIn( errors, name )
  const inputValid = pristine || error===undefined
  return(
    <FormFieldColorPicker
      name={name}
      value={value}
      valid={inputValid}
      error={error}
      {...inputProps}
      onChangeComplete={value=>{
        console.log( "Color", value )
        //setFieldValue(name,value.hex)
        setFieldValue(name,`rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`)
        setFieldTouched(name)
        userOnChange&&userOnChange(undefined,{name,value})
      }}
      onBlur={e=>{
        setFieldTouched(name)
        userOnBlur&&userOnBlur(e,{name})
      }}
    />
  )
}

const FormColorPicker = props => {
  const { onChange, onBlur, ...pickerProps } = props
  return(
    <Field
      component={FormFieldColorPickerWrapper}
      userOnChange={onChange}
      userOnBlur={onBlur}
      {...pickerProps}
    />
  )
}

FormColorPicker.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

FormColorPicker.defaultProps = {
};

export default FormColorPicker
