import { delay, call, put, all, select, takeLatest } from 'redux-saga/effects'

import * as constants from '../../constants'
import * as periodConstants from '../../entities/period/constants'
import * as eventConstants from '../../entities/event/constants'
import * as periodApi from '../../entities/period/api'
import * as eventApi from '../../entities/event/api'
import { getPeriodState, getEventState } from './selectors'
import { fetchDependentData, fetchFilteredRecords } from '../../model/sagas'

function *fetchBaseData() {
  yield call( fetchDependentData, [ 'account', 'eventAttrs', 'periodAttrs' ] )
}

function* loadPeriodRecords( debounce, action ) {
  try {
    if ( debounce )
      yield call( delay, debounce )
    const periodState = yield select(getPeriodState)
    //yield call( fetchBaseData )
    yield call( fetchFilteredRecords, periodState, periodApi.getRecords, periodConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* generatePeriods( action ) {
  try {
    yield call( [ periodApi, 'generateRecords' ], action.payload )
    yield put({type: periodConstants.EDIT_MODAL_CLOSE})
    yield call( loadPeriodRecords, 0 );
  } catch (e) {
    console.error( e )
  }
}

function* loadPeriodRecord( action ) {
  try {
    //yield call( fetchBaseData )
    yield call( [ periodApi, 'getRecord' ], action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function *periodRecordLoaded( action ) {
  const record = action.payload;
  yield put({type: periodConstants.EDIT_MODAL_OPEN, payload: record});
}

function* updatePeriodRecord( action ) {
  try {
    yield call( [ periodApi, 'updateRecord' ],  action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *periodRecordUpdated( action ) {
  yield put({type: periodConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadPeriodRecords, 0 );
}

function *deletePeriod( action ) {
  const record = action.payload;
  yield put({type: periodConstants.DELETE_MODAL_OPEN, payload: record});
}

function* deletePeriodRecord( action ) {
  try {
    yield call( [ periodApi, 'deleteRecord' ], action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *periodRecordDeleted( action ) {
  yield put({type: periodConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadPeriodRecords, 0 )
}

function *refreshRecords() {
  yield all([
    call( fetchBaseData ),
    call( loadPeriodRecords, 0 ),
    call( loadEventRecords, 0 ),
  ])
}

function* loadEventRecords( debounce, action ) {
  try {
    if ( debounce )
      yield call( delay, debounce )
    const eventState = yield select(getEventState)
    yield call( fetchBaseData )
    yield call( fetchFilteredRecords, eventState, eventApi.getRecords, eventConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* updateEventRecord( action ) {
  try {
    yield call( [ eventApi, 'updateRecord' ],  action.payload )
  } catch (e) {
    console.error( e )
  }
}

function *eventRecordUpdated( action ) {
  yield put({type: eventConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadEventRecords, 0 );
}

function *deleteEvent( action ) {
  const record = action.payload;
  yield put({type: eventConstants.DELETE_MODAL_OPEN, payload: record});
}

function* deleteEventRecord( action ) {
  try {
    yield call( [ eventApi, 'deleteEvent' ], action.payload.record )
  } catch (e) {
    console.error( e )
  }
}

function *eventRecordDeleted( action ) {
  yield put({type: eventConstants.EDIT_MODAL_CLOSE});
  yield put({type: constants.RESET_UPDATED});
  yield call( loadEventRecords, 0 )
}

export default function*() {
  yield takeLatest( periodConstants.RECORDS_LOAD, loadPeriodRecords, 0 );
  //yield takeLatest( periodConstants.RECORDS_PAGE, loadPeriodRecords, 0 );
  //yield takeLatest( periodConstants.RECORDS_SORT, loadPeriodRecords, 0 );
  yield takeLatest( periodConstants.RECORDS_SEARCH, loadPeriodRecords, 250 );
  yield takeLatest( periodConstants.RECORDS_FILTER, loadPeriodRecords, 0 );
  yield takeLatest( periodConstants.RECORDS_DATE, loadPeriodRecords, 0 );
  yield takeLatest( periodConstants.RECORDS_RESET, loadPeriodRecords, 0 );
  yield takeLatest( periodConstants.RECORDS_GENERATE, generatePeriods );
  yield takeLatest( periodConstants.RECORD_LOAD, loadPeriodRecord );
  yield takeLatest( periodConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX, periodRecordLoaded );
  yield takeLatest( periodConstants.RECORD_UPDATE, updatePeriodRecord );
  yield takeLatest( periodConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, periodRecordUpdated );
  yield takeLatest( periodConstants.RECORD_DELETE, deletePeriodRecord );
  yield takeLatest( periodConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, periodRecordDeleted );
  yield takeLatest( periodConstants.DELETE, deletePeriod );
  //yield takeLatest( eventConstants.ADD, addEvent );
  //yield takeLatest( eventConstants.EDIT, editEvent );
  yield takeLatest( eventConstants.RECORDS_LOAD, loadEventRecords, 0 );
  yield takeLatest( eventConstants.RECORD_UPDATE, updateEventRecord );
  yield takeLatest( eventConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, eventRecordUpdated );
  yield takeLatest( eventConstants.RECORD_DELETE, deleteEventRecord );
  yield takeLatest( eventConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, eventRecordDeleted );
  yield takeLatest( eventConstants.DELETE, deleteEvent );
  //yield takeLatest( eventConstants.CANCEL, cancelEvent );
  yield takeLatest( constants.UPDATE_REFRESH, refreshRecords );
}
