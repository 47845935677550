import produce from 'immer'

import * as constants from '../constants'

const initialState = {
};

const modelReducer = function( state = initialState, action )
{
  switch ( action.type )
  {
    case constants.MODEL_DATA_UPDATE :
      return produce( state, newState => {
        Object.keys(action.payload).forEach( key => {
          newState[key] = action.payload[key]
        })
      })
    case constants.MODEL_DATA_EXPIRE :
      return produce( state, newState => {
        action.payload.forEach( key => {
          delete newState[key]
        })
      })
    case constants.MODEL_DATA_CLEAR :
      return produce( state, newState => {
        return {}
      })
    default :
  }
  return state;
}

export default modelReducer;
