import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Input } from 'semantic-ui-react'

import FormFieldWrapper from './field'

const FormInput = props => {
  const { onChange, onBlur, ...inputProps } = props
  return(
    <Field
      component={FormFieldWrapper}
      fieldType={Input}
      userOnChange={onChange}
      userOnBlur={onBlur}
      {...inputProps}
    />
  )
}

export default FormInput
