import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { IntlProvider } from 'react-intl'
import createSagaMiddleware from 'redux-saga'
import { fork, all } from 'redux-saga/effects';
import * as serviceWorker from './serviceWorker';

import history from './history';
//import notificationWatcher from './notifications'
import reducers from './reducers'
import appSaga from './app/sagas'
import loginSaga from './views/access/sagas'
import calendarSaga from './views/calendar/sagas'
import definitionsSaga from './views/definitions/sagas'
import rulesSaga from './views/rules/sagas'
import accountSaga from './views/account/sagas'
import api from './api/'
import ErrorTrap from './sentry'
import './logrocket'
import App from './app/'

function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(loginSaga),
    fork(calendarSaga),
    fork(rulesSaga),
    fork(definitionsSaga),
    fork(accountSaga),
  ]);
}

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const monitor = store => next => action => {
  console.log( "ACTION", action );
  console.log( "STORE", store.getState() );
  next(action);
};

const store = createStore(
  reducers(history),
  undefined,
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
//      notificationWatcher,
      monitor
    )
  )
);

api.attach( store );

// then run the saga
sagaMiddleware.run(rootSaga)

render(
  <Provider store={store}>
    <IntlProvider locale="en-gb">
      <ConnectedRouter history={history}>
        <ErrorTrap>
          <App/>
        </ErrorTrap>
      </ConnectedRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
